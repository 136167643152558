import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import "../../assets/styles/product/wholesaleproductcard.scss";
import Logo from "../../assets/images/logo.png";

import { FaRegHeart, FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";

import { FaCheckCircle } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { set_home_categories } from "../../redux/actions/HomeAction";
import { useTranslation } from "react-i18next";
import { CashbackOffer } from "../customer/account/CashbackOffer";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ffd700",
  },
  iconHover: {
    color: "#ffd700",
  },
  iconEmpty: {
    color: "#404553",
  },
})(Rating);

const WholesaleProductCard = (props) => {
  const [adding, setAdding] = useState(false);
  const [wishlisted, setWishlisted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const wishlistToggle = () => {
    if (localStorage.getItem("access_token")) {
      setAdding(true);
      apiJson.cart
        .moveToWishlist(
          {
            wishlist_item: {
              orderable_type:
                props.product.type === "products" ? "Variant" : "Service",
              orderable_id:
                props.product.type === "products"
                  ? props.product.line_item_id
                  : props.product.id,
            },
          },
          props.countryId
        )
        .then((response) => {
          setAdding(false);
          if (response.success) {
            setWishlisted(!wishlisted);
            props.home &&
              props.set_home_categories({
                positionOne: {
                  ...props.positionOne,
                  data: props.positionOne?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionTwo: {
                  ...props.positionTwo,
                  data: props.positionTwo?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionThree: {
                  ...props.positionThree,
                  data: props.positionThree?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionFour: {
                  ...props.positionFour,
                  data: props.positionFour?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                positionFive: {
                  ...props.positionFive,
                  data: props.positionFive?.data?.map((data) => {
                    return data.variants
                      ? {
                        ...data,
                        variants: data.variants.map((variant) =>
                          variant.id === response.data.line_item.id
                            ? { ...variant, wishlisted: !variant.wishlisted }
                            : variant
                        ),
                      }
                      : {
                        ...data,
                        wishlisted:
                          data.id === response.data.line_item.id
                            ? !data.wishlisted
                            : data.wishlisted,
                      };
                  }),
                },
                sections: props.sections.map((section) => {
                  return {
                    ...section,
                    data: section.data.map((data) => {
                      return data.variants
                        ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? {
                                ...variant,
                                wishlisted: !variant.wishlisted,
                              }
                              : variant
                          ),
                        }
                        : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                    }),
                  };
                }),
              });
          } else if (!response.status) {
            message =
              props.language === "ar" ? "هناك خطأ ما" : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      message =
        props.language === "en"
          ? "Please login first"
          : "الرجاء تسجيل الدخول أولا";
      showError();
      setRedirect(true);
    }
  };

  useEffect(
    () => setWishlisted(props.product.wishlisted),
    [props.product.wishlisted]
  );
  return (
    <div
      className="wholesale-product-card-container-desktop mb-2"
      style={{
        height: props.product.is_inquiry ? "fit-content" : "auto"
      }}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname:
            "/" +
            props.countryUrl +
            "-" +
            props.language +
            `/wholesale/${props.product.category_en?.toLowerCase().replace(/ /g, "-")}/${props.product.slug
            }`,
          state: { id: props.product.id, type: props.product.type, sku: props.product.sku },
          search: "?sku=" + props.product.sku
        }}
      >
        <Row className={`product-badge-row mx-auto `}>
          {props.width !== "sm" && (
            <div
              className={`badge-container ${props.product.badge ? "" : "invisible w-0"
                }`}
            ></div>
          )}
          {
            props.product.is_inquiry ?
              <></>
              :
              props.product.price_before_discount != props.product.price_after_discount ?
                <div className={props.language == "en" ? "discount-box" : "discount-box-ar"}>
                  <p
                    style={{ color: "white", fontSize: "12px" }}
                    className="font-weight-bold m-0"
                  >
                    &nbsp;
                    {Math.round(
                      ((props.product.price_before_discount - props.product.price_after_discount) /
                        props.product.price_before_discount) *
                      100
                    )}
                    % OFF
                  </p>
                </div>
                :
                <></>
          }
          <div
            onClick={(e) => {
              e.preventDefault();
              wishlistToggle();
            }}
            className="pointer like-button-container"
          >
            {adding ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <ClipLoader color="#000" loading size={20} />
              </div>
            ) : wishlisted ? (
              <FaHeart color="#d4982b" size={24} />
            ) : (
              <FaRegHeart color="grey" size={24} />
            )}
          </div>
        </Row>
        <Row className="product-image-row mx-auto">
          <img className="img mx-auto" src={props.product.img} alt="product" />
        </Row>
        <Row className="product-description-row mx-auto">
          {
            props.product.is_inquiry ?
              <></>
              :
              <div className="coupon-row">
                {
                  props.product.coupon ?
                    new Date(props.product.coupon.expiry) >= new Date() ?
                      <div className="coupon-box mb-1">
                        <p className="px-2 my-0">Coupon: {props.product.coupon.code}</p>
                      </div>
                      :
                      <></>
                    :
                    <></>
                }
              </div>
          }
          {props.product.cashback && (
            <CashbackOffer cashback={props.product.cashback}/>
          )}  
          {
            props.product.promo ?
              <div
                className={`product-promo-container mx-auto ${props.product.promo ? "" : "invisible"
                  }`}
              >
                <div className="product-promo">{props.product.promo}</div>
              </div>
              :
              <></>
          }

          <div className="product-description-container">
            <div className="product-description">
              {props.product.description}
            </div>
          </div>
          <div className="product-description-container">
            {/* <div className="product-description"> */}
            {props.product.variant_name}
            {/* </div> */}
          </div>
          {
            props.product.is_inquiry ?
              <></>
              :
              props.product.product_type == "wholesale" ?
                <div className="product-price-container">
                  <div className="regular-price">
                    <span className="currency fw-700">{props.country.toLowerCase() === "saudi arabia"
                      ? "SAR"
                      : props.country.toLowerCase() === "united arab emirates"
                        ? "AED"
                        : "EGP"}{" "}</span>
                    <span className="value">{props.product.start_price}</span>
                    <span className="value"> - </span>
                    <span className="currency fw-700">{props.country.toLowerCase() === "saudi arabia"
                      ? "SAR"
                      : props.country.toLowerCase() === "united arab emirates"
                        ? "AED"
                        : "EGP"}{" "}</span>
                    <span className="value">{props.product.end_price}</span>
                  </div>
                </div>
                :
                <div className="product-price-container">
                  <div className={props.product.price_after_discount == props.product.price_before_discount ? "regular-price" : "price-after-discount"}>
                    <span className="currency fw-700">
                      {props.country.toLowerCase() === "saudi arabia"
                        ? "SAR"
                        : props.country.toLowerCase() === "united arab emirates"
                          ? "AED"
                          : "EGP"}{" "}
                    </span>
                    <span className="value">
                      {props.product.price_after_discount}
                    </span>
                  </div>
                  <div
                    className={`${props.product.price_before_discount -
                      props.product.price_after_discount >
                      0
                      ? ""
                      : "invisible "
                      }price-before-discount text-uppercase`}
                  >
                    <span className="currency">
                      {props.country.toLowerCase() === "saudi arabia"
                        ? "SAR"
                        : props.country.toLowerCase() === "united arab emirates"
                          ? "AED"
                          : "EGP"}{" "}
                    </span>
                    <span className="value">
                      {props.product.price_before_discount}
                    </span>
                  </div>
                </div>
          }
        </Row>
        <Row className="product-rating-row mx-auto">
          <div className="product-rating-container d-flex align-items-center">
            <StyledRating
              name="customized-empty"
              defaultValue={2}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              size="small"
              readOnly
              value={props.product.rating}
            />
            <div className="product-rating-count">{`(${props.product.count})`}</div>
          </div>
        </Row>
        <Row className="seller-info-row mx-auto">
          <div className="seller-info-container">
            <div className="seller-logo">
              <img
                src={props.product.storeLogo ? props.product.storeLogo : Logo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "25px",
                  maxWidth: "85px",
                  maxHeight: "25px",
                }}
              />
            </div>
            <div className="seller-verified">
              <FaCheckCircle />
            </div>

            <div className="d-flex flex-direction-row align-items-center rating-mobile-cont">
              <BsFillStarFill size="12" color="rgb(245, 165, 35)" />
              <span className="rating-mobile-value">
                {props.product.rating}
              </span>
              <span className="rating-mobile-count">{`(${props.product.count})`}</span>
            </div>
          </div>
        </Row>
      </Link>
      {
        props.product.is_inquiry ?
          <a
            className="inquire-now-btn"
            href={encodeURI(`//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${props.product['description_' + props.language]}. Check here: ${process.env.REACT_APP_WEBSITE_URL + "/" + props.countryUrl + "-" + props.language + "/" + props.product.category_en?.toLowerCase().replace(/ /g, "-") + "/" + props.product?.slug}?sku=${props.product?.sku}`)}
            target="_blank"
          >{t("item-detail.get-inquiry")}</a>
          :
          <></>
      }
      {redirect && <Redirect push to="/login" />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    bestSellers: state.home.bestSellers,
    newArrival: state.home.newArrival,
    countryId: state.language.countryId,
    sections: state.home.sections,

    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
  };
};

const mapDispatchToProps = { set_home_categories };
export default connect(mapStateToProps, mapDispatchToProps)(WholesaleProductCard);
