import React, { Fragment, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Redirect, useLocation } from "react-router-dom";

import "../../../assets/styles/customer/account/sidebar.scss";
import { TiDocumentText } from "react-icons/ti";
import { FaRegAddressBook } from "react-icons/fa";
import { GoCreditCard } from "react-icons/go";
import { BiWallet } from "react-icons/bi";
import { MdAssignmentReturn } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { set_user } from "../../../redux/actions/AuthAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { apiJson } from "../../../Api";
import { RiCurrencyLine } from "react-icons/ri";

const Sidebar = (props) => {
  const [signingOut, setSigningOut] = useState(false);
  const { t } = useTranslation();
  var order = t("side-bar-desktop.order");
  var address = t("side-bar-desktop.addresses");
  var payment = t("side-bar-desktop.payments");
  var wallet = t("side-bar-desktop.wallet");
  var returns = t("side-bar-desktop.returns");
  var disputes = t("side-bar-desktop.disputes");
  var profile = t("side-bar-desktop.profile");
  let refer = t("side-bar-desktop.refer");
  const [redirect, setRedirect] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const SIDEBAR_MENU_ITEMS = [
    {
      id: 1,
      name_t: order,
      name: "Orders",
    },
    {
      id: 2,
      name_t: address,
      name: "Addresses",
    },
    {
      id: 3,
      name_t: payment,
      name: "Payments",
    },
    {
      id: 4,
      name_t: wallet,
      name: "Wallet",
    },
    {
      id: 5,
      name_t: returns,
      name: "Returns",
    },
    {
      id: 6,
      name_t: disputes,
      name: "Disputes",
    },
    {
      id: 7,
      name_t: refer,
      name: "refer",
    },
    {
      id: 8,
      name_t: profile,
      name: "Profile",
    },
  ];

  const [currentSidebarMenuItem, setCurrentSidebarMenuItem] = useState(
    SIDEBAR_MENU_ITEMS[0]
  );

  const location = useLocation();

  const switchSelectedMenuItem = (event) => {
    const SELECTED = event.target.innerText;

    if (currentSidebarMenuItem !== SELECTED) {
      setCurrentSidebarMenuItem(SELECTED);
    }
  };
  const signout = () => {
    setSigningOut(true);
    apiJson.pushnotificationmessages.removeToken().then(() => {
    apiJson.auth.signout().then((response) => {
      if (response.success) {
        document.cookie =
          "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
        localStorage.removeItem("access_token");
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("guest_user_address");
        localStorage.removeItem("referral_link");
        props.set_user({ isSignedIn: false, user: {} });
        props.set_cart_items({ cart_items: { sub_total: 0, line_items: [] } });
        setRedirect(true);
      } else {
        setSigningOut(false);
        if (
          parseInt(response.error?.response?.status) === 404 ||
          parseInt(response.error?.response?.status) === 401
        ) {
          document.cookie =
            "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          localStorage.removeItem("access_token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          localStorage.removeItem("guest_user_address");
          props.set_user({ isSignedIn: false, user: {} });
          props.set_cart_items({
            cart_items: { sub_total: 0, line_items: [] },
          });
          setRedirect(true);
        } else {
          message =
            props.language === "ar" ? "هناك خطأ ما" : "something went wrong";
          showError();
        }
      }
    });
  })
  };

  const getIcon = (item) => {
    switch (item) {
      case order:
        return <TiDocumentText />;
      case address:
        return <FaRegAddressBook />;
      case payment:
        return <GoCreditCard />;
      case wallet:
        return <BiWallet />;
      case returns:
        return <MdAssignmentReturn />;
      case disputes:
        return <IoWarningOutline />;
      case refer:
        return <RiCurrencyLine />;  
      case profile:
        return <AiOutlineUser />;
      default:
        return <TiDocumentText />;
    }
  };

  return (
    <Fragment>
      <Row className="customer-sidebar no-gutters pt-4 ps-3">
        <Col md={12} className="p-0">
          <div className="top black-text fw-500">
            <div className="greeting fw-600">
              {t("customer-profile-address.hala")} {props.user?.first_name_en}
            </div>
            <div
              onClick={!signingOut ? signout : undefined}
              className="signout pt-1 fw-500 d-flex align-items-center"
            >
              <span className="unstyled-anchor mr-2">
                {t("customer-profile-address.sign-out")}
              </span>
              {signingOut && <ClipLoader color="#000" size="13" />}
            </div>
          </div>
          <div className="items pt-3">
            {SIDEBAR_MENU_ITEMS.map((item) => {
              const path = location.pathname;
              return (
                <div
                  className={`sidebar-menu-item fw-600 ${
                    "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${item.name.toLowerCase()}` !==
                    path
                      ? "unselected"
                      : "selected"
                  }`}
                  key={item.id}
                  onClick={switchSelectedMenuItem}
                >
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      `/${item.name.toLowerCase()}`
                    }
                  >
                    <div className="d-flex justify-content align-items-center inner-item">
                      <div className="icon me-2 d-inline-flex justify-content-center align-items-center p-1">
                        {getIcon(item.name_t)}
                      </div>
                      <div className="ms-2 d-inline-block">{item.name_t}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      {redirect && <Redirect push to="/login" />}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  set_user,
  set_cart_items,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
