import React from "react";
import { connect } from "react-redux";

import RamadanTermsAndConditionsComp from "../../../components/mobile/footer-pages/RamadanTermsAndConditionsComp";

function RamadanTermsAndConditions(props) {
  return <div>{props.width === "sm" && <RamadanTermsAndConditionsComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(RamadanTermsAndConditions);
