import React, { Fragment, useState, useEffect } from "react";
import { IoChevronForward } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import "../../assets/styles/customer/order.scss";
import Empty from "../../assets/images/orders_art.svg";
import { set_orders } from "../../redux/actions/OrdersAction";
import Dropdown from "react-dropdown";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import YelloColorImage from "../../assets/images/homepage/YelloColorImage.png";
import { getDesktopImage, getMobileImage } from "../../utils/utils";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const orderList = (props, order_items, t, getImage) =>
  order_items.map((order) => {
    return (
      <div key={order.id} className="order d-flex">
        <div className="image-container text-center">
          <img
            src={getImage(order.featured_image_resized, order.featured_image)}
            alt=""
            style={{ width: "auto", maxWidth: "70px", height: "70px" }}
          />
        </div>
        <div className="description-container">
          <p className="description">
            {order[`product_name_${props.language}`]
              ? order[`product_name_${props.language}`] +
              " " +
              order[`variant_name_${props.language}`]
              : order[`service_name_${props.language}`]}
          </p>
          <p className="delivered text-uppercase">
            {(order.status == "delivered" || order.status == "collected") ? (
              <span className="green">
                {
                  order.status ?
                    order.status == "delivered" ?
                      props.language == "en" ?
                        en_translation["order"]["delivered"]
                        :
                        ar_translation["order"]["delivered"]
                      :
                      props.language == "en" ?
                        en_translation["order"]["collected"]
                        :
                        ar_translation["order"]["collected"]
                    :
                    ""
                }
              </span>
            ) : (
              <span className="black">
                {
                  order.status ?
                    props.language == "en" ?
                      en_translation["order"][order.status.toLowerCase()]
                      :
                      ar_translation["order"][order.status.toLowerCase()]
                    :
                    ""
                }
              </span>
            )}
          </p>
        </div>
      </div>
    );
  });

const Order = (props) => {
  const { t } = useTranslation();
  const viewOptions = ["The start", "The end"];
  const arabicViewOptions = ["البداية", "النهاية"];
  const [activeView, setActiveView] = useState(
    props.language?.toLowerCase() === "en" ? "The end" : "النهاية"
  );
  const [redirectToOrder, setRedirectToOrder] = useState({
    value: false,
    id: -1,
  });
  useEffect(() => {
    if (props.language?.toLowerCase() === "en") {
      if (activeView === "البداية") {
        setActiveView("The start");
      } else if (activeView === "النهاية") {
        setActiveView("The end");
      }
    } else {
      if (activeView === "The start") {
        setActiveView("البداية");
      } else if (activeView === "The end") {
        setActiveView("النهاية");
      }
    }
  }, [props.language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="order-component  order-component-mobile">
        {props.orders.length > 0 ? (
          <div className="row order-row d-flex mx-auto">
            <div className="right-section">
              <div className="image-container">
                {props.banners[0]?.image ? (
                  <>
                    {props.banners[0]?.redirects_to_link ? (
                      <a href={props.banners[0]?.redirects_to_link}>
                        <img
                          src={props.banners[0]?.image || YelloColorImage}
                          alt="order"
                          width="100%"
                          height="150px"
                        />
                      </a>
                    ) : (
                      <img
                        src={props.banners[0]?.image || YelloColorImage}
                        alt="order"
                        width="100%"
                        height="150px"
                      />
                    )}
                  </>
                ) : (
                  <img
                    src={YelloColorImage}
                    alt="order"
                    width="100%"
                    height="150px"
                  />
                )}
              </div>

              <div className="order-list">
                <div className="header-container d-flex align-items-center">
                  <div className="heading-container">
                    <div className="heading">{t("order.title")}</div>
                    <p className="mb-0">{t("order.detail")}</p>
                  </div>
                  <div className="filter-container d-flex align-items-center justify-content-end">
                    <div className="label">{t("order.filter-label")}</div>
                    <div className="filter-search">
                      <Dropdown
                        options={
                          props.language?.toLowerCase() === "en"
                            ? viewOptions
                            : arabicViewOptions
                        }
                        onChange={(e) => {
                          setActiveView(e.value);
                          if (
                            props.language?.toLowerCase() === "en"
                              ? e.value === "The end"
                              : e.value === "النهاية"
                          ) {
                            props.set_orders({
                              orders: props.orders.sort(
                                (a, b) =>
                                  new Date(b.created_at) -
                                  new Date(a.created_at)
                              ),
                            });
                          } else {
                            props.set_orders({
                              orders: props.orders.sort(
                                (a, b) =>
                                  new Date(a.created_at) -
                                  new Date(b.created_at)
                              ),
                            });
                          }
                          setActiveView(e.value);
                        }}
                        value={activeView}
                        placeholderClassName="dropdownPlaceholder"
                        menuClassName="dropdownPlaceholder"
                        className="dropdown-cont"
                      />
                    </div>
                  </div>
                </div>

                <div className="mobile-header">
                  <div className="text-style">{t("order.filter-label")}</div>
                  <Dropdown
                    options={
                      props.language?.toLowerCase() === "en"
                        ? viewOptions
                        : arabicViewOptions
                    }
                    onChange={(e) => {
                      setActiveView(e.value);
                      if (
                        props.language?.toLowerCase() === "en"
                          ? e.value === "The end"
                          : e.value === "النهاية"
                      ) {
                        props.set_orders({
                          orders: props.orders.sort(
                            (a, b) =>
                              new Date(b.created_at) - new Date(a.created_at)
                          ),
                        });
                      } else {
                        props.set_orders({
                          orders: props.orders.sort(
                            (a, b) =>
                              new Date(a.created_at) - new Date(b.created_at)
                          ),
                        });
                      }
                      setActiveView(e.value);
                    }}
                    value={activeView}
                    placeholderClassName="dropdownPlaceholder"
                    menuClassName="dropdownPlaceholder"
                    className="dropdown-cont"
                  />
                </div>
                {props.orders.map((order) => (
                  <div className="order-container custom-card" key={order.id}>
                    <div
                      className="pointer order-heading-container d-flex align-items-center justify-content-between px-2"
                      onClick={() => {
                        setRedirectToOrder({ value: true, id: order.id });
                        const timer = setTimeout(() => {
                          setRedirectToOrder({ value: false, id: -1 });
                        }, 1000);
                        clearTimeout(timer);
                      }}
                    >
                      <div
                        className="pointer order-id-container"
                        style={{ textAlign: "initial" }}
                      >
                        <span className="order-id">
                          {t("order.order")} {order.id}
                        </span>
                        <span className="order-date">
                          {t("order.placed-on")}
                          {moment(new Date(order.created_at)).format("LL")}
                        </span>
                      </div>
                      <div className="more">
                        <span className="large-screen-none">
                          {t("order.view-details")}
                        </span>
                        <IoChevronForward
                          className="forward-icon"
                          style={{
                            transform:
                              props.language?.toLowerCase() === "en"
                                ? "unset"
                                : `rotate(180deg)`,
                          }}
                        />
                      </div>
                    </div>
                    {order.cashback_message && <div style={{ padding: "8px 8px 0",
    fontSize: "14px" }}><span className="description">{order.cashback_message}</span></div>}

                    <div className="orders d-flex justify-content-start align-items-center">
                      {orderList(
                        props,
                        order.line_items,
                        t,
                        props.width === "sm" ? getMobileImage : getDesktopImage
                      )}
                    </div>
                    {order &&
                      (order.status.toLowerCase() === "delivered" ||
                        order.status.toLowerCase() === "collected" ||
                        order.status.toLowerCase() === "partially_returned" ||
                        order.status.toLowerCase() === "fully_returned") && (
                        <Fragment>
                          <div className="border-line"></div>
                          <div className="review-container">
                            <button
                              type="button"
                              className="review d-flex justify-content-start align-items-center px-2"
                              style={{
                                direction:
                                  props.width?.toLowerCase() === "sm"
                                    ? props.language?.toLowerCase() === "en"
                                      ? "rtl"
                                      : "ltr"
                                    : props.language?.toLowerCase() === "en"
                                      ? "ltr"
                                      : "rtl",
                              }}
                            >
                              <Link
                                to={
                                  "/" +
                                  props.countryUrl +
                                  "-" +
                                  props.language +
                                  "/order/itemslist/review"
                                }
                                style={{ textDecoration: "none" }}
                              >
                                <BiLike className="large-screen-none" />
                                {t("order.review-order")}
                                <BiLike className="small-screen-none" />
                              </Link>
                            </button>
                          </div>
                        </Fragment>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-cont">
            <div className="img-cont">
              <img
                src={Empty}
                className="img-fluid"
                width="100%"
                height="100%"
                alt=""
              />
            </div>
            <div className="empty-bold-text mt-5">
              {t("order.empty.no-orders")}
            </div>
            <div className="empty-text">{t("order.empty.start-shopping")}</div>
          </div>
        )}
      </div>
      {redirectToOrder.value && (
        <Redirect
          push
          to={
            "/" +
            props.countryUrl +
            "-" +
            props.language +
            `/order/details/${redirectToOrder.id}`
          }
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
    orders: state.order.orders,
    banners: state.order.banners,
    width: state.layout.screenWidth,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_orders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
