import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/component/mobile/walletcardmob.scss";
import { FcPlus } from "react-icons/fc";
import { FaMinusCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_wallet_credits } from "../../redux/actions/WalletAction";
import { connect } from "react-redux";
import EmptyWallet from "../../assets/images/wallet_art.svg";
import moment from "moment";
import { set_loading } from "../../redux/actions/LayoutAction";

const WalletCardMob = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    props.set_loading(true);
    apiJson.wallet.getCredit().then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_wallet_credits({ credit: response.data });
        setData(true);
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const renderTransactionType = (type, item) => {
    switch (type) {
      case "order_type":
        return item[`order_payment_details_${props.language}`];
      case "refund_type":
        return item[`refund_details_${props.language}`];
      case "cashback_type":
        return t("refer.cashback-rewarded");
      default:
        return "";
    }
  };

  const renderType = (type) => {
    switch (type) {
      case "order_type":
        return t("wallet-mobile.order-payment");
      case "refund_type":
        return t("wallet-mobile.refund");
      case "cashback_type":
        return t("refer.cashback");
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <>
        {!data ? (
          <></>
        ) : props.credit.length < 1 ? (
          <>
            <div className="wallet-empty-card-area">
              <div className="image-container">
                <img
                  src={EmptyWallet}
                  className="img-fluid"
                  alt=""
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="cart-text cart-head-text">
                {t("wallet-mobile.empty")}
              </div>
            </div>
          </>
        ) : (
          <>
            {props.credit.map((item, index) => (
              <div
                key={index}
                className="main-container-wallet-card-mobile px-3 mb-3 mt-3 pt-1 pb-2"
              >
                <div className="wallet-card-inner-container">
                  <div className="main-row">
                    {(item.transaction_type === "refund_type" || item.transaction_type === "cashback_type") && (
                      <FcPlus size={20} />
                    ) ||
                      (item.transaction_type === "order_type" && (
                        <FaMinusCircle color="red" size={18} />
                      ))}
                    <div className="card-first-row">
                      <div className="left">
                        <div className="return-refund-label">
                          <span className="returned-or-refunded">
                            {renderType(item.transaction_type)}
                          </span>
                        </div>
                      </div>
                      <div className="right">
                        {item.type === "refund_type" ? (
                          <span className="red-color">-</span>
                        ) : (
                          ""
                        )}
                        <span
                          className={`${
                            item.transaction_type === "refund_type" || item.transaction_type === "cashback_type"
                              ? "green-color"
                              : "red-color"
                          }`}
                        >
                          {item.currency.toUpperCase() + " " + item.amount}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-second-row mt-1 pb-1">
                    <div className="left">
                      <span className="date-ret-ref label-for-balance">
                        {moment(new Date(item.created_at)).format("lll")}
                      </span>
                    </div>
                    <div className="right">
                      <span className="balance-span label-for-balance">
                        {t("wallet.balance")}
                      </span>
                      <span className="balance-ammount">
                        {" "}
                        {item.currency.toUpperCase() +
                          " " +
                          item.remaining_balance}
                      </span>
                    </div>
                  </div>
                  <div className="card-third-row mt-3">
                    <span className="product-description">
                      {renderTransactionType(item.transaction_type, item)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    credit: state.wallet.credit,
    transfer: state.wallet.tranfer,
    language: state.language.language,
    load: state.layout.loading,
  };
};
const mapDispatchToProps = { set_wallet_credits, set_loading };
export default connect(mapStateToProps, mapDispatchToProps)(WalletCardMob);
