import React, { useState, useEffect } from "react";
import "../../assets/styles/mobile/walletpagemob.scss";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { apiJson } from "../../Api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import HeaderMobile from "../../components/mobile/HeaderMobile";
import ReferCardMob from "../../components/mobile/ReferCardMob";
import { MdShare } from "react-icons/md";
import ReferLinkModel from "../../components/customer/account/ReferLinkModel";
import { getCurrencyOfCountry } from "../../utils/utils";
import CopyButton from "../../components/customer/account/CopyButton";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";

const ReferPageMob = (props) => {
  const [open, setOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [totalEarn, setTotalEarn] = useState(0);
  const [referralCashback, setReferralCashback] = useState({});

  const referralLink = localStorage.getItem("referral_link");

  const { t } = useTranslation();

  const fbSendUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    referralLink
  )}`;

  const whatsappMessage = t(`refer.referral_message`, {
    amount:
      getCurrencyOfCountry(props.country) +
      " " +
      parseFloat(referralCashback.amount),
    referralLink: referralLink,
  });

  useEffect(() => {
    document.title = "Refer & Earn";

    apiJson.refer.getReferrals().then((response) => {
      if (response.success) {
        setReferrals(response.data.referrals);
        setTotalEarn(response.data.total_cashback_amount);
      }
    });

    apiJson.refer.referralCashback().then((response) => {
      if (response.success) {
        setReferralCashback(response.data.cashback || {});
      }
    });
  }, []);

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>

      <div className="wallet-page-mob-main-container">
        <div className="balance mt-3">
          <span>{t("refer.title")}</span>
        </div>

        {referralCashback.amount && (
          <p
            style={{ textAlign: "initial", textAlign: "center", margin: 0 }}
            className="gray below-title"
          >
            {t("refer.referral", {
              amount: parseFloat(referralCashback.amount),
            })}
          </p>
        )}

        <div className="d-flex justify-content-between p-3">
          <div>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <div>
                <div style={{ fontSize: "12px", fontWeight: 500 }}>
                  {referralLink}
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <a
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                      whatsappMessage
                    )}`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FaWhatsapp size={20} color="#4dc247" />
                  </a>

                  <a href={fbSendUrl} target="_blank">
                    <FaFacebook size={20} />
                  </a>
                  <span
                    type="button"
                    className="cursor"
                    onClick={() => setOpen(true)}
                  >
                    <MdShare fontSize={20} />
                  </span>
                  <span>
                    <CopyButton referralLink={referralLink} isSmall={true} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: 600,
                width: "max-content",
              }}
            >
              {t("refer.total-earn")}
            </div>
            <div style={{ fontSize: "12px", width: "max-content" }}>
              {getCurrencyOfCountry(props.country)} {parseFloat(totalEarn)}
            </div>
          </div>
        </div>

        <div className="wallet-card-lsit-inpage px-3">
          <ReferCardMob referrals={referrals} />
        </div>

        {open && (
          <ReferLinkModel
            isOpen={open}
            setIsOpen={setOpen}
            isSmall={true}
            referralLink={referralLink}
            amount={referralCashback.amount}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => ({ country: state.language.country });

export default connect(mapStateToProps, null)(ReferPageMob);
