import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import '../../../assets/styles/customer/account/general.scss';
import '../../../assets/styles/pages/walletpage.scss';
import WebsiteHeader from '../../../components/header/WebsiteHeader';
import Sidebar from '../../../components/customer/account/Sidebar';
import Refer from '../../../components/customer/account/Refer';

const ReferPage = () => {
  useEffect(() => {
    document.title = 'Refer & Earn';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className="page-container no-gutters">
      <div className="w-100">
        <WebsiteHeader />

        <div className="content-container all-page-container d-flex justify-content-center mx-auto">
          <div className="left-pane">
            <Sidebar />
          </div>

          <div className="right-pane">
            <Refer />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default ReferPage;
