import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getCurrencyOfCountry } from "../../../utils/utils";
import { connect } from "react-redux";

export const CashbackOffer = ({ cashback, isListPage = false }) => {
  const {
    amount,
    percentage,
    cashback_type,
    expiry_date,
    country,
    max_cashback,
    min_order_amount,
  } = cashback;

  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    if (!expiry_date) return;

    const interval = setInterval(() => {
      const now = moment();
      const expiry = moment(expiry_date);
      const diff = moment.duration(expiry.diff(now));

      if (diff.asMilliseconds() <= 0) {
        setTimeLeft("");
        clearInterval(interval);
      } else {
        let timeString = [];

        if (diff.days() > 0) {
          timeString.push(`${diff.days()}d`);
        }
        if (diff.hours() > 0 || diff.days() > 0) {
          timeString.push(`${diff.hours()}h`);
        }
        if (diff.minutes() > 0 || diff.hours() > 0 || diff.days() > 0) {
          timeString.push(`${diff.minutes()}m`);
        }
        timeString.push(`${diff.seconds()}s left`);

        setTimeLeft(timeString.join(" : "));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiry_date]);

  return (
    <div>
      <p className="font-bold m-0">
        <span style={{ lineHeight: 1, fontWeight: 700, color: "#742013" }}>
          {cashback_type === "amount"
            ? t("cashback.get-cashback-amount-minimum-order", {
                amount:
                  getCurrencyOfCountry(country) + " " + parseFloat(amount),
                minimumAmount:
                  getCurrencyOfCountry(country) +
                  " " +
                  parseFloat(min_order_amount),
              })
            : t("cashback.get-cashback-amount", {
                percentage: parseFloat(percentage) + "%",
                maxAmount:
                  getCurrencyOfCountry(country) +
                  " " +
                  parseFloat(max_cashback),
                minimumAmount:
                  getCurrencyOfCountry(country) +
                  " " +
                  parseFloat(min_order_amount),
              })}
        </span>
      </p>
      <p className="m-0">
        <span style={{ lineHeight: 1, fontWeight: 700, color: "#742013" }}>
          {!isListPage && t("cashback.expires-in", { time: timeLeft })}
        </span>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  country: state.language.country,
});

export default connect(mapStateToProps, null)(CashbackOffer);
