import { type } from "../../types/types";
const initialState = {
  primaryBanners: [],
  secondaryBanners: [],
  categories: {},
  positionOne: {},
  positionTwo: {},
  positionThree: {},
  positionFour: {},
  positionFive: {},
  positionSix: {},
  positionSeven: {},
  positionEight: {},
  positionNine: {},
  positionTen: {},
  positionEleven: {},
  positionTwelve: {},
  positionThirteen: {},
  positionFourteen: {},
  sections: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case type.SET_HOME_CATEGORIES:
      return {
        ...state,
        ...action.categories,
      };
    default:
      return state;
  }
}
