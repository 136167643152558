import React, { Fragment, useEffect, useState } from "react";

import "../../../assets/styles/customer/account/wallet.scss";
import "../../../assets/styles/general.scss";
import "../../../assets/styles/utils.scss";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MdShare } from "react-icons/md";
import { apiJson } from "../../../Api";
import ReferLinkModel from "./ReferLinkModel";
import { getCurrencyOfCountry } from "../../../utils/utils";
import CopyButton from "./CopyButton";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

const Refer = (props) => {
  const [open, setOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [referralCashback, setReferralCashback] = useState({});
  const [totalEarn, setTotalEarn] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const referralLink = localStorage.getItem("referral_link");

  useEffect(() => {
    setLoading(true);
    apiJson.refer.getReferrals().then((response) => {
      if (response.success) {
        setReferrals(response.data.referrals);
        setTotalEarn(response.data.total_cashback_amount);
      }
      setLoading(false);
    });

    apiJson.refer.referralCashback().then((response) => {
      if (response.success) {
        setReferralCashback(response.data.cashback || {});
      }
    });
  }, []);

  const fbSendUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    referralLink
  )}`;

  const whatsappMessage = t(`refer.referral_message`, {
    amount:
      getCurrencyOfCountry(props.country) +
      " " +
      parseFloat(referralCashback.amount),
    referralLink: referralLink,
  });

  const renderTable = () => {
    return (
      <>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <ClipLoader color="#000" loading size={20} />
          </div>
        ) : (
          <>
            <div className="title">Referrals History</div>
            <div className="credits-table wallet-table">
              <div className="table-responsive">
                <Table hover className="overflow-auto">
                  <thead>
                    <tr className="table-head">
                      <td className="pl-4 w-10 fw-500">{t("refer.date")}</td>
                      <td className="w-10 fw-500">{t("refer.name")}</td>
                      <td className="w-10 fw-500">{t("refer.status")}</td>
                      <td className="w-10 fw-500">
                        {t("refer.referral_cashback")}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral) => {
                      return (
                        <tr key={referral.id}>
                          <td className="date gray word-break-none pl-4">
                            {moment(referral.created_at).format("ll")}
                          </td>

                          <td>{referral.referee_user}</td>
                          <td>{referral.status}</td>
                          <td className="amount green word-break-none">
                            {" "}
                            + {getCurrencyOfCountry(props.country)}{" "}
                            {parseFloat(referral.cashback_amount) || 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
        {!referrals?.length && !loading && (
          <div style={{ margin: "40px", textAlign: "center" }}>
            {t("refer.empty-refer")}
          </div>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <div className="customer-wallet w-100 py-3">
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="title">{t("refer.title")}</div>
              {referralCashback.amount && (
                <p
                  style={{ textAlign: "initial", margin: 0 }}
                  className="gray below-title"
                >
                  {t("refer.referral", {
                    amount: parseFloat(referralCashback.amount),
                  })}
                </p>
              )}
            </div>
            <div>
              <div className="right green">{t("refer.total-earn")}</div>
              <div className="right start">
                {getCurrencyOfCountry(props.country)} {parseFloat(totalEarn)}
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              gap: "10px",
              marginBottom: "15px",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 500 }}>
              {referralLink}
            </span>
            <a
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                whatsappMessage
              )}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FaWhatsapp size={30} color="#4dc247" />
            </a>

            <a href={fbSendUrl} target="_blank">
              <FaFacebook size={30} />
            </a>
            <span
              type="button"
              className="cursor"
              onClick={() => setOpen(true)}
            >
              <MdShare fontSize={24} />
            </span>
            <CopyButton referralLink={referralLink} />
          </div>
        </div>

        {renderTable()}
      </div>

      {open && (
        <ReferLinkModel
          isOpen={open}
          setIsOpen={setOpen}
          isSmall={false}
          referralLink={referralLink}
          amount={referralCashback.amount}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  country: state.language.country,
});

export default connect(mapStateToProps, null)(Refer);
