import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

import TopBanner1 from "../../../assets/images/RamadanOffer2024/TopBanner/Ramadan_V2_800x300.png";
import { FaInfoCircle } from "react-icons/fa";


import "../../../assets/styles/category-homepages/ramadan-page-mob.scss";
import { apiJson } from "../../../Api";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";

import cat1 from "../../../assets/images/ramadan-offer-2025/categories/1.png";
import cat2 from "../../../assets/images/ramadan-offer-2025/categories/2.png";
import cat3 from "../../../assets/images/ramadan-offer-2025/categories/3.png";
import cat4 from "../../../assets/images/ramadan-offer-2025/categories/4.png";
import cat5 from "../../../assets/images/ramadan-offer-2025/categories/5.png";
import cat6 from "../../../assets/images/ramadan-offer-2025/categories/6.png";
import cat7 from "../../../assets/images/ramadan-offer-2025/categories/7.png";
import cat8 from "../../../assets/images/ramadan-offer-2025/categories/8.png";
import cat9 from "../../../assets/images/ramadan-offer-2025/categories/9.png";
import cat10 from "../../../assets/images/ramadan-offer-2025/categories/10.png";

import RamadanEssential1 from "../../../assets/images/ramadan-offer-2025/essentials/1.png";
import RamadanEssential2 from "../../../assets/images/ramadan-offer-2025/essentials/2.png";
import RamadanEssential3 from "../../../assets/images/ramadan-offer-2025/essentials/3.png";
import RamadanEssential4 from "../../../assets/images/ramadan-offer-2025/essentials/4.png";
import RamadanEssential5 from "../../../assets/images/ramadan-offer-2025/essentials/5.png";
import RamadanEssential6 from "../../../assets/images/ramadan-offer-2025/essentials/6.png";

import CategoryBanner1 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/1.png";
import CategoryBanner2 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/2.png";

import CleaningBanner1 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/3.png";
import CleaningBanner2 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/4.png";
import CleaningBanner3 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/5.png";
import CleaningBanner4 from "../../../assets/images/ramadan-offer-2025/banners_grid/800x300/6.png";


import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const RamadanMobile = (props) => {
  const [products1, setProducts1] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [products3, setProducts3] = useState([]);
  const [products4, setProducts4] = useState([]);
  const [products5, setProducts5] = useState([]);

  useEffect(() => {
    window.document.title = "Ramadan Offer - Alisuq";

    apiJson.
      sections.getSectionProducts(
        84,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts1(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        85,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts2(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        86,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts3(response.data.products);
        }
      });

    apiJson.
      sections.getSectionProducts(
        87,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts4(response.data.products);
        }
      });
    apiJson.
      sections.getSectionProducts(
        88,
        props.countryId,
        1,
        10
      )
      .then(response => {
        if (response.success) {
          setProducts5(response.data.products);
        }
      });

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>
      <div className="banner-sec">
        <Swiper className="catpages-mob"
          spaceBetween={0}
          slidesPerView={1}
          pagination={false}
          navigation={true}
          >
          <SwiperSlide
            onClick={(e) => e.preventDefault()}
            key={1}
          >
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/ramadan-sale"
            }}>
              <img
                src={TopBanner1}
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="ramadantcmob">
                        <span className="ramadantc">
                            <Link className="ramadantclink" to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/Ramadan-terms-and-conditions"
                            }}>
                              <FaInfoCircle style={{ color: '#55555591',marginRight:'3px' }} />View Ramadan Cashback T&Cs
                            </Link>
                        </span>

                    </div>
      <div className="ramadan-page-mob pt-4">

        <div className="px-2">
          <h2 className="sectiontitle-individualpage mb-2">Top Ramadan Categories</h2>

          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            pagination={false}
            navigation={true}
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=204"
              }}>
                <img
                  src={cat1}
                  alt="Alisuq cleaner essentials"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=262"
              }}>
                <img
                  src={cat2}
                  alt="Alisuq Hand tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=667"
              }}>
                <img
                  src={cat3}
                  alt="Alisuq Kitchen Accessories"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=498"
              }}>
                <img
                  src={cat4}
                  alt="Kitchen appliances"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=193"
              }}>
                <img
                  src={cat5}
                  alt="Alisuq Power Tools"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=201"
              }}>
                <img
                  src={cat6}
                  alt="Alisuq Vacuums"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=212"
              }}>
                <img
                  src={cat7}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=587"
              }}>
                <img
                  src={cat8}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=282"
              }}>
                <img
                  src={cat9}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=506"
              }}>
                <img
                  src={cat10}
                  alt="Alisuq Water Bottles"
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Best Deals</h2>
          <div className="product-cont">
            {products1?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="emptyspacer"></div>


        <h2 className="sectiontitle-individualpage mb-2">Ramadan Essentials</h2>
        <div
          className="row mx-0 ramadancatpics"
        >
          <div className="col-6 left-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=464"
            }}>
              <img
                src={RamadanEssential1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=499&filter_by_category%5B%5D=496"
            }}>
              <img
                src={RamadanEssential2}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=226&filter_by_category%5B%5D=227"
            }}>
              <img
                src={RamadanEssential3}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=272&filter_by_category%5B%5D=269"
            }}>
              <img
                src={RamadanEssential4}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217"
            }}>
              <img
                src={RamadanEssential5}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-6 right-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=273&filter_by_category%5B%5D=251"
            }}>
              <img
                src={RamadanEssential6}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">We Recommend</h2>
          <div className="product-cont">
            {products2?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Home Improvements</h2>
        <div className="row ramadanhome">
          <div className="col-6 left-deal">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=204"
            }}>
              <img src={CategoryBanner1} className="img img-fluid" />
            </Link>
          </div>
          <div className="col-6 right-deal mt-0">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=186"
            }}>
              <img src={CategoryBanner2} className="img img-fluid" />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Tools</h2>
          <div className="product-cont">
            {products3?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Cleaning Supplies</h2>
        <div
          className="row mx-0 ramadancleaning"
        >
          <div className="col-12">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=194"
            }}>
              <img
                src={CleaningBanner1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 left-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=191"
            }}>
              <img
                src={CleaningBanner2}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 center-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=193"
            }}>
              <img
                src={CleaningBanner3}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
          <div className="col-4 right-deal" style={{ marginTop: "14px" }}>
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_category%5B%5D=192"
            }}>
              <img
                src={CleaningBanner4}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>
        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Cleaners</h2>
          <div className="product-cont">
            {products4?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="emptyspacer"></div>
        <h2 className="sectiontitle-individualpage mb-2">Storage Solutions</h2>
        <div
          className="row mx-0 ramadancleaning"
        >
          <div className="col-12">
            <Link to={{
              pathname: "/" + props.countryUrl +
                "-" + props.language + "/items/products",
              search: "filter_by_brand%5B%5D=177"
            }}>
              <img
                src={CleaningBanner1}
                alt="Alisuq cleaner essentials"
                className="img img-fluid"
                height="110px"
              />
            </Link>
          </div>

        </div>

        <div className="emptyspacer"></div>


        <div className="surfacepat">
          <h2 className="sectiontitle-individualpage mb-2">Storage, Organizers & Bins</h2>
          <div className="product-cont">
            {products5?.map((data) => (
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${data.category_title_en
                      .toLowerCase()
                      .replace(/ /g, "-")}/${data.slug}`,
                  state: {
                    id: data.id,
                    type: data.variants ? "products" : "services",
                    sku: data.variants[0].sku
                  },
                }}
                key={data.id + Math.random()}
              >
                <div
                  className="image-cont"
                  key={data.id + Math.random() + "prod"}
                >
                  <ProductCard
                    key={data.id + Math.random()}
                    img={
                      data.variants
                        ? data.variants[0].featured_image
                        : data.featured_image
                    }
                    storeLogo={data.store_logo}
                    wishlisted={
                      data.variants
                        ? data.variants[0].wishlisted
                        : data.wishlisted
                    }
                    home={true}
                    type={data.variants ? "products" : "services"}
                    id={data.variants ? data.id : data.id}
                    rating={data.variants[0].average_rating}
                    reviews={data.variants[0].reviews_count}
                    name={data[`name_${props.language}`] + " " + data.variants[0][`name_${props.language}`]}
                    data={data.variants ? data.variants[0] : data}
                    coupon={data.coupon}
                    is_inquiry={data.is_inquiry}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>





      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};


export default connect(mapStateToProps, null)(RamadanMobile);
