import React, { Fragment } from "react";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Terms-&-Conditions.jpg";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { useEffect } from "react";
import $ from "jquery";

const RamadanTermsAndConditions = () => {
  useEffect(() => {
    window.document.title = "Alisuq Terms & Conditions";

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />

        <div className="content-wrapper all-page-container">
          <div
            className="footer-pages-content"
            style={{
              textAlign: "left",
            }}
          >
            <p>
              <span className="ramadantermsheading">Ramadan Cashback Offer – Terms & Conditions</span>
            </p>

            <b>Introduction</b>
            <p>Welcome to Alisuq’s Ramadan Cashback Offer! To make your shopping experience even more rewarding, we’re offering 100% cashback on your next order. Please read the following terms and conditions carefully before participating in this campaign.</p>

            <b>Campaign Period</b>
            <p>The Ramadan Cashback Offer is valid from February 28, 2025, to March 31, 2025.</p>

            <b>Eligibility & Participation</b>
            <p>1. Only customers registered on Alisuq are eligible to participate in the Ramadan Cashback Offer.</p>
            <p>2. In order to qualify, customers must make a purchase of at least 150 AED on Alisuq.</p>
            <p>3. The first 100 customers who make a minimum purchase of 150 AED will qualify for the 100% cashback.</p>
            <p>4. Cashback is applicable on the first order with a minimum purchase of AED 150 and will be credited to the customer’s Alisuq account after the 7-day return window.</p>
            <p>5. Cashback will be credited to the customer’s Alisuq account and can be used on a future purchase.</p>

            <b>Cashback Details</b>
            <p>1. The cashback is valid for use until June 1, 2025.</p>
            <p>2. The maximum cashback a customer can receive is AED 50.</p>
            <p>3. Cashback cannot be transferred, exchanged for cash, or used on multiple accounts.</p>
            <p>4. Cashback will be credited to the customer’s Alisuq account after the 7-day return window has ended.</p>

            <b>General Terms</b>
            <p>1. This offer is limited to one cashback per customer during the campaign period.</p>
            <p>2. Orders that are cancelled or refunded will not be eligible for cashback.</p>
            <p>3. This offer cannot be combined with any other discounts or promotions.</p>
            <p>4. Alisuq reserves the right to modify, extend, or cancel this offer at any time without prior notice.</p>
            <p>5. In case of any disputes, Alisuq’s decision will be final.</p>

            <b>What is our cancellation policy?</b>
            <p>Cancellation before shipment: If the order or the item(s) that you want to cancel have not been shipped yet, you can write to our customer support team on customerservice@alisuq.com or call us on +971543064845 (Sunday to Thursday, 8AM to 5PM and Saturday, 8AM to 12PM). In such cases, the order will be cancelled, and the money will be refunded to you within 7-14 days after the cancellation request is duly processed by us.</p>

            <b>How long do I have to return an item?</b>
            <p>You have 7 days from the day of delivery to return any item that meets our return policy.</p>

            <b>What is our return policy?</b>
            <p>Thank you for shopping at Alisuq, we hope you enjoyed your experience! Alisuq offers its customers an ’Easy return policy’, wherein you can raise a return/refund request of a product within 7 days of its delivery.</p>
            <p>- We will refund any item(s) accompanied by original proof of purchase, such as your receipt or tax invoice.</p>
            <p>- The returned item(s) must be in good and unused condition, in its original sealed packaging and with all paperwork, parts and accessories that came along with it.</p>
            <p>- There may be some other items for which returns may not be possible or a restocking fee may apply.</p>

            <b>How will I get refunded for the cancelled orders and how long will this process take?</b>
            <p>In case of cancellation before shipment, we process the refund within 24-48 business hours after receiving the cancellation request.</p>
            <p>- For payments done through credit/debit cards or net banking, the refund will be processed through Alisuq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>
            <p>- For cash on delivery transactions, the refund will be processed through Alisuq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>

            <b>What if I used discount vouchers during the time of payment and I have to cancel my order?</b>
            <p>Discount vouchers are intended for one-time use only and shall be treated as used even if you cancel the order.</p>

            <b>Which are the items that cannot be returned?</b>
            <p>Returns will not be accepted under the following conditions:</p>
            <p>- Product is damaged due to misuse/overuse.</p>
            <p>- Returned without original packaging including, price tags, labels, original packing, freebies and other accessories or if original packaging is damaged.</p>
            <p>- Serial Number is tampered with.</p>
            <p>- Product is used or altered.</p>
            <p>- Free product provided by brand.</p>

            <b>Categories not eligible for Return:</b>
            <p>- Customized Products cannot be returned since they are available during select promotions and ordered on demand.</p>
            <p>- Brand specified which are not returnable.</p>

            <b>Can I return part of my order?</b>
            <p>Yes. A return can be created at item level and if you have ordered multiple items, you can initiate a return/refund for any individual item.</p>

            <b>How will I get refunded for the returned orders and how long will this process take?</b>
            <p>In case of a return/replacement/refund, we process the refund once the products have been received and verified at our warehouse.</p>
            <p>- For payments done through credit/debit cards or net banking, the refund will be processed through Alisuq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>
            <p>- For cash on delivery transactions, the refund will be processed through Alisuq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>

            <b>Will I get my VAT refunded when I return items?</b>
            <p>The VAT amount will be included in your total refund amount. Make sure to show your proof of purchase - receipt or tax invoice - when returning your item(s).</p>

            <b>Is there a cash on delivery charge?</b>
            <p>We will charge an additional AED10 for cash on delivery orders.</p>



          </div>
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default RamadanTermsAndConditions;
