import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdContentCopy } from "react-icons/md";

const CopyButton = ({ referralLink, isSmall }) => {
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);

    setTooltipVisible(true);

    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  return (
    <span style={{ position: "relative" }}>
      <span style={{ cursor: "pointer" }} onClick={handleCopy}>
        <MdContentCopy fontSize={isSmall ? 20 : 24} />
      </span>

      {tooltipVisible && (
        <div
          style={{
            position: "absolute",
            top: "30px",
            left: "0",
            backgroundColor: "#742013",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "12px",
            zIndex: 10,
          }}
        >
          {t("refer.copied")}
        </div>
      )}
    </span>
  );
};

export default CopyButton;
