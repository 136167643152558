import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useOutsideAlerter } from "../../../utils/CustomHooks";
import "../../../assets/styles/component/customer/itemDetail/ItemCmp.scss";
import Banner from "../../../assets/images/camping/banners/WinterSale-2023.jpg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import CartSidebar from "../cart/CartSidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import { set_single_item } from "../../../redux/actions/SingleItemAction";
import DateAndTimePickers from "./DateAndTimePickers";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from 'react-bootstrap/Modal';
import { IoCloseSharp } from "react-icons/io5";
import AssemblyServiceModel from "../AssemblyServiceModel";
import { CashbackOffer } from "../account/CashbackOffer";

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

function ItemCmpMobile(props) {
  const sideCartRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [active, setActive] = useState(
    props.data.variants ? parseInt(props.data.variants[0].id) : 0
  );
  const variantList = props.data.variants;
  const { t } = useTranslation();
  const [showCart, setShowCart] = useState(false);
  useOutsideAlerter(sideCartRef, setShowCart);
  const [quantityVal, setQuantityVal] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const [quantity, setQuantity] = useState("1");
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  const [isOpen, setIsOpen] = useState(false);
  const [imageGalleryIsVisible, setImageGalleryIsVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  useEffect(() => {
    if (props.cart_items_length === 0) {
      setShowCart(false);
    }
  }, [props.cart_items_length]);

  useEffect(() => {
    props.data.order_quantity &&
      setQuantityVal(
        props.data.order_quantity
          ? Array.from({ length: props.data.order_quantity }, (_, i) => i + 1)
          : undefined
      );
    props.data.order_quantity && setQuantity("1");
  }, [props.data.order_quantity]);

  useEffect(() => {
    if (props.current_variant_key) {
      setActive(props.current_variant_key);
      handleChange({ value: props.current_variant_key });
    }
  }, [props.current_variant_key]);

  const handleChange = (e) => {
    props.data.variants.map((variant) =>
      variant.id === e.value
        ?
        variant.images ?
          props.setActiveImg(variant.featured_image, [
            {
              id: variant.id + "_" + 0,
              src: variant.featured_image,
              active: true,
            },
            ...variant.images?.map((ele, index) => {
              return {
                id: variant.id + "_" + (index + 1),
                src: ele,
                active: false,
              };
            }),
          ])
          :
          props.setActiveImg(variant.featured_image, [
            {
              id: variant.id + "_" + 0,
              src: variant.featured_image,
              active: true,
            }
          ])
        : undefined
    );
    let q = props.data.variants
      .map((variant) =>
        variant.id === e.value
          ? parseInt(variant.quantity) < 1
            ? -1
            : variant.quantity
          : undefined
      )
      .filter((variant) => variant !== undefined)[0];

    props.set_single_item({
      item: {
        ...props.item,
        variantName_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.name_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        description_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`description_en`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        description_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`description_ar`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        sku: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`sku`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        variantName_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.name_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        variantId: props.data.variants
          .map((variant) => (variant.id === e.value ? variant.id : undefined))
          .filter((variant) => variant !== undefined)[0],
        price_before_discount: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.price : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        order_quantity: q === -1 ? undefined : q,

        price_after_discount: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.discounted_price : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        wishlisted: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.wishlisted : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        order_id: props.data.variants
          .map((variant) => (variant.id === e.value ? variant.id : undefined))
          .filter((variant) => variant !== undefined)[0],

        benefits_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.benefits_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        benefits_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.benefits_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        features_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.features_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        features_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.features_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        how_to_use_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.how_to_use_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        how_to_use_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.how_to_use_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
      },
    });
  };

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorTop = () => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const moveToWishlist = () => {
    setLoading(true);
    apiJson.cart
      .moveToWishlist(
        {
          wishlist_item: {
            orderable_type:
              props.data.type === "Product" ? "Variant" : "Service",
            orderable_id: props.data.order_id,
          },
        },
        props.countryId
      )
      .then((response) => {
        setLoading(false);
        if (response.success) {
          if (response.data.added) {
            props.set_single_item({
              item: {
                ...props.item,
                wishlisted: !props.data.wishlisted,
                variants: props.data.variants
                  ? props.data.variants.map((variant) =>
                    variant.id === props.data.order_id
                      ? { ...variant, wishlisted: !variant.wishlisted }
                      : variant
                  )
                  : false,
              },
            });
          } else {
            props.set_single_item({
              item: {
                ...props.item,
                wishlisted: !props.data.wishlisted,
                variants: props.data.variants
                  ? props.data.variants.map((variant) =>
                    variant.id === props.data.order_id
                      ? { ...variant, wishlisted: !variant.wishlisted }
                      : variant
                  )
                  : false,
              },
            });
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const addToCart = () => {
    setAdding(true);
    apiJson.cart
      .addToCart(
        props.data.type === "Product"
          ? {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: props.data.variantId,
              is_assembly_added: props.addAssembly,
              ...(props.addAssembly && { assembly_id: props.item.assembly.id })
            },
          }
          : {
            line_item: {
              orderable_type: "Service",
              orderable_id: props.data.order_id,
              service_type: props.data?.service_type,
              start_at: new Date(startDate).toString(),
              ...(props.data?.service_type !== "one_time" && { end_at: new Date(endDate).toString() })
            },
          },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            setShowCart(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            setShowCart(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  const imageMagnifyEn = {
    zIndex: 9,
  };
  const imageMagnifyAr = {
    zIndex: 9,
    left: "-100%",
    marginLeft: "-10px",
  };
  const onCarouselClicked = (index) => {
    let temp_images = [];
    props.Img.map(img => {
      temp_images.push({
        original: img.src,
        thumbnail: img.src
      });
    });
    setImages(temp_images);
    setImageGalleryIsVisible(true);
    setSelectedImage(index);
  }
  const closeImageGallery = () => {
    setImages([]);
    setImageGalleryIsVisible(false);
    setSelectedImage(0);
  }
  return (
    <div className="item-cmp-desktop">
      {
        props.videoClicked ?
          <div
            style={{ direction: "ltr" }}
            className="img-div d-flex justify-content-center"
          >
            <div
              className="mb-1 innerhtml top-video-div"
              dangerouslySetInnerHTML={{
                __html: props.data["video"],
              }}
            ></div>
          </div>
          :
          <div
            style={{ direction: "ltr" }}
            className="img-div d-flex justify-content-center"
          >
            {props.activeImg && (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "",
                    isFluidWidth: true,
                    src: props.activeImg,
                  },
                  largeImage: {
                    src: props.activeImg,
                    width: 1000,
                    height: 850,
                  },
                  style: {
                    maxHeight: "100%",
                  },
                  imageStyle: {
                    maxHeight: "100%",
                    objectFit: "contain",
                  },
                  enlargedImageStyle: {
                    objectFit: "contain",
                    backgroundColor: "white"
                  },
                  enlargedImageContainerStyle:
                    props.language === "ar" ? imageMagnifyAr : imageMagnifyEn,
                }}
              />
            )}
          </div>
      }

      <div className="large-screen-none ">
        <Carousel controls={false}>
          {props.Img?.map((item, index) => (
            <Carousel.Item interval={2000} key={item.id} className="w-100" onClick={() => onCarouselClicked(index)}>
              <div
                key={item.id}
                className="d-flex justify-content-center max-height-width"
              >
                <img
                  alt={index}
                  src={item.src}
                  style={{ width: "auto", maxWidth: "100%", height: "100%" }}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <Modal
        show={imageGalleryIsVisible}
        onHide={closeImageGallery}
        closeModal={closeImageGallery}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="item-cmp-desktop"
      >
        <Modal.Body className="image-gallery-modal">
          <IoCloseSharp
            color="gray"
            fontSize={22}
            onClick={closeImageGallery}
            className="cross-icon"
          />
          <ImageGallery
            items={images}
            startIndex={selectedImage}
            fullscreen={false}
            showPlayButton={false}
            showFullscreenButton={false}
            className="custom-image-gallery"
          />
        </Modal.Body>
      </Modal>

      <div className="text-div" style={{ textAlign: "initial" }}>
        {/* <div className="vendure-text">{props.data.order_vendor}</div> */}
        {/* <div className="vendure-text123">
          <Link to={{
            pathname: "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/items/products",
            search: "?filter_by_brand%5B%5D=" + props.data.brand_id
          }}
          >{props.data.brand_name}</Link>
            
             <span className="seperator-line">|</span>
          <a
            href={
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/vendor/shop/" +
              props.data.store_id
            }
            className="anchor-style"
          >
            Visit the {" "}<span className="storename">{props.data.order_vendor}</span> Store
          </a>
      
        </div>

        <h1 className="item-title">
          {props.data?.order_title + " "}
          {props.data
            ? props.data[`variantName_${props.language}`]
              ? props.data[`variantName_${props.language}`]
              : ""
            : ""}
        </h1> */}
        {/* <div className="vendure-text">
          {props.data?.sku ? props.data[`sku`] : ""}
        </div> */}
        {
          props.data.coupon ?
            new Date(props.data.coupon.expiry) >= new Date() ?
              <div className="coupon-box">
                <p className="px-2 my-0">Coupon: {props.data.coupon.code}</p>
              </div>
              :
              <></>
            :
            <></>
        }
            {props.data.cashback && (
              <CashbackOffer cashback={props.data.cashback} />
            )}  
        <div style={{ display: "flex", marginTop: "10px" }}>
          {
            props.data?.sku ?
              <div className="vendure-text">
                <span>Model no. :&nbsp;</span>{props.data[`sku`] ? props.data[`sku`] : ""}
              </div>
              :
              <></>
          }
          {
            props.data?.upc_code ?
              <>
                <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 18, border: "1px solid gray" }}></div>
                <div className="vendure-text">
                  <span>UPC Code:&nbsp;</span>{props.data?.upc_code ? props.data[`upc_code`] : ""}
                </div>
              </>
              :
              <></>
          }
        </div>
        <div className="prev-price small-screen-none">
          {props.data.price_before_discount - props.data.price_after_discount >
            0 && t("item-detail.was")}
          {props.data.price_before_discount - props.data.price_after_discount >
            0 && (
              <span className="ml-4">
                <s>
                  {props.data.currency} {props.data.price_before_discount}
                </s>
              </span>
            )}
          <span
            className="rating-style"
            style={{
              marginInlineStart:
                props.data.price_before_discount -
                  props.data.price_after_discount <
                  1
                  ? "0px"
                  : "12px",
            }}
          >
            {parseFloat(props.data.rating).toFixed(2)}
          </span>
          <span className="rating-count">
            {props.data.rating_count} {t("item-detail.itemcmp-rating")}
          </span>
        </div>
        <div className="prev-price small-screen-none">
          {props.data.price_before_discount - props.data.price_after_discount >
            0 && t("item-detail.now")}
          <span
            className={`${props.data.price_before_discount -
              props.data.price_after_discount >
              0
              ? "ml-4 "
              : ""
              } price-style`}
          >
            {props.data.currency}{" "}
            {Math.round(
              (parseFloat(props.data.price_after_discount) + Number.EPSILON) *
              100
            ) / 100}
          </span>
          <span className="inclusive-style">
            {t("item-detail.inclusive-of-vat")}
          </span>
        </div>

        {
          props.data?.is_inquiry ?
            <></>
            :
            props.data?.product_type == "wholesale" ?
              <div>
                <div className="row price-row price-row-mob mx-0">
                  <div className="col-4 col1">
                    <p className="m-0 price">{props.data.currency} {props.data?.qty1range_price}</p>
                    <p className="m-0">{props.data?.qty1range_from} &lt; {props.data?.qty1range_to} {props.item?.unit}</p>
                  </div>
                  <div className="col-4 col2">
                    <p className="m-0 price">{props.data.currency} {props.data?.qty2range_price}</p>
                    <p className="m-0">{props.data?.qty2range_from} &lt; {props.data?.qty2range_to} {props.item?.unit}</p>
                  </div>
                  <div className="col-4 col-3">
                    <p className="m-0 price">{props.data.currency} {props.data?.qty3range_price}</p>
                    <p className="m-0">{props.data?.qty3range_from} &lt; {props.data?.qty3range_to} {props.item?.unit}</p>
                  </div>
                </div>
                {/* <table className="table table-bordered m-0">
                  <tr>
                    <th className="py-1">Quantity</th>
                    <th className="py-1">Price</th>
                  </tr>
                  <tr>
                    <td className="py-1">{props.data?.qty1range_from}-{props.data?.qty1range_to}</td>
                    <td className="py-1">{props.data.currency} {props.data?.qty1range_price}</td>
                  </tr>
                  {
                    props.data?.qty2range_from ?
                      <tr>
                        <td className="py-1">{props.data?.qty2range_from}-{props.data?.qty2range_to}</td>
                        <td className="py-1">{props.data.currency} {props.data?.qty2range_price}</td>
                      </tr>
                      :
                      <></>
                  }
                  {
                    props.data?.qty3range_from ?
                      <tr>
                        <td className="py-1">{props.data?.qty3range_from}-{props.data?.qty3range_to}</td>
                        <td className="py-1">{props.data.currency} {props.data?.qty3range_price}</td>
                      </tr>
                      :
                      <></>
                  }
                </table> */}
                <span className="inclusive-style p-0">
                  {t("item-detail.include-vat-text")}
                </span>
              </div>
              :
              <div className="large-screen-none">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="price-style">
                      {props.data.currency}{" "}
                      {Math.round(
                        (parseFloat(props.data.price_after_discount) +
                          Number.EPSILON) *
                        100
                      ) / 100}
                      {props.data.type == "Service" &&
                        (props.data?.service_type === "one_time"
                          ?
                          <div className="price-style" style={{ fontSize: "1rem !important" }}>
                            {
                              props.language == "en"
                                ? en_translation["item-detail"]["one-time-service"]
                                : ar_translation["item-detail"]["one-time-service"]
                            }
                          </div>
                          : props.language == "en"
                            ? en_translation["item-detail"]["hourly-service"]
                            : ar_translation["item-detail"]["hourly-service"])}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#7E859B",
                        marginLeft: "5px",
                      }}
                    >
                      {props.data.price_before_discount -
                        props.data.price_after_discount >
                        0 && (
                          <s>
                            {props.data.currency}{" "}
                            {Math.round(
                              (parseFloat(props.data.price_before_discount) +
                                Number.EPSILON) *
                              100
                            ) / 100}
                            {props.data.type == "Service" &&
                              (props.data?.service_type === "one_time"
                                ? props.language == "en"
                                  ? en_translation["item-detail"]["one-time-service"]
                                  : ar_translation["item-detail"]["one-time-service"]
                                : props.language == "en"
                                  ? en_translation["item-detail"]["hourly-service"]
                                  : ar_translation["item-detail"]["hourly-service"])}                     </s>
                        )}
                    </span>
                  </span>
                  <span className="inclusive-style">
                    {t("item-detail.inclusive-of-vat")}
                  </span>
                </div>
              </div>
        }
        {
          props.data?.is_inquiry ?
            <></>
            :
            props.data?.product_type == "wholesale" ?
              <></>
              :
              parseFloat(
                props.data.price_before_discount - props.data.price_after_discount
              ) > 0
                ?
                <span
                  className={`large-screen-none off-perc-style ${parseFloat(
                    props.data.price_before_discount - props.data.price_after_discount
                  ) > 0
                    ? ""
                    : " invisible"
                    }`}
                >
                  {(
                    ((props.data.price_before_discount -
                      props.data.price_after_discount) *
                      100) /
                    props.data.price_before_discount
                  ).toFixed(2)}
                  {t("item-detail.off")}
                </span>
                :
                <></>
        }
        {props.item?.assembly && (
          <AssemblyServiceModel
            assemblyPrice={`${props.data.currency} ${props.item.assembly?.price}`}
            isSmall={true}
            addAssembly={props.addAssembly}
            setAddAssembly={props.setAddAssembly}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
        {props.data.type === "Product" &&
          variantList.map((variant, index) => (
            <div key={index} className="mb-3 mt-3">
              {active === variant.id && (
                <span className="variation-text">
                  {t("item-detail.variation")}
                  {": "}
                  <strong>{variant.variation}</strong>
                </span>
              )}
            </div>
          ))}
        {props.data.type === "Product" && (
          <div className="city-cont">
            {variantList.map((variant) => (
              <Link
                to={{
                  pathname: "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${props.item.category_title_en?.toLowerCase().replace(/ /g, "-")}/${props.item.slug
                    }`,
                  search: "?sku=" + encodeURIComponent(variant.sku)
                }}
                onClick={() => {
                  if (active !== variant.id) {
                    handleChange({ value: variant.id });
                    setActive(variant.id);
                  }
                }}
                className={
                  active === variant.id
                    ? "selected-span px-3 py-2 mb-2"
                    : "simple-span px-3 py-2 mb-2"
                }
                key={variant.id}
              >
                <img
                  src={variant.featured_image}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "100%",
                  }}
                  alt=""
                />
              </Link>
            ))}
          </div>
        )}
        {
          props.data.type == "Service" && (
            <p>{
              props.language == "en" ?
                en_translation["item-detail"]["service-text"]
                :
                ar_translation["item-detail"]["service-text"]
            }</p>
          )
        }
        <div className="quantity-cont small-screen-none">
          {props.data.order_quantity && (
            <div className="quantity-heading small-screen-none">
              {t("item-detail.quantity")}
            </div>
          )}

          <div className="d-flex flex-direction-column quantity-body ">
            {props.data.order_quantity && (
              <span className="dropdown-cont">
                <Dropdown
                  options={quantityVal}
                  onChange={(e) => setQuantity(e)}
                  value={quantity}
                  placeholderClassName="dropdownPlaceholder"
                  menuClassName="dropdownMenu"
                  className="dropdown-outer"
                />
              </span>
            )}

            {props.data.type === "Product" ? (
              <span
                onClick={() => props.data.order_quantity && addToCart()}
                className={`${!props.data.order_quantity ? "out-of-stock" : "add-to-cont"
                  }`}
              >
                <span className="add-to-cart-text">
                  {adding ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : props.data.order_quantity ? (
                    props.language == "en" ?
                      en_translation["item-detail"]["add-to-cart"]
                      :
                      ar_translation["item-detail"]["add-to-cart"]
                  ) : (
                    t("item-detail.out-of-stock")
                  )}
                </span>
              </span>
            ) : (
              <span onClick={() => addToCart()} className="add-to-cont">
                <span className="add-to-cart-text">
                  {adding ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    props.language == "en" ?
                      en_translation["item-detail"]["add-to-cart"]
                      :
                      ar_translation["item-detail"]["add-to-cart"]
                  )}
                </span>
              </span>
            )}
            <span className="like-cont">
              <span
                onClick={() => {
                  if (localStorage.getItem("access_token")) {
                    moveToWishlist();
                  } else {
                    setRedirect(true);
                  }
                }}
                className="like-icon"
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : props.data.wishlisted ? (
                  <FaHeart size="20" color="#F64951" />
                ) : (
                  <FaRegHeart size="20" color="#ABB7C5" />
                )}
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* {
        props.item.store_id == 88 ?
          <div className="mb-4">
            <Link
              to={{
                pathname: "/" + props.countryUrl + "-" + props.language + "/winter-sale"
              }}
            >
              <img src={Banner} className="img img-fluid" />
            </Link>
          </div>
          :
          <></>
      } */}
      {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      {redirect && <Redirect push to="/login" />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.item.item,
    width: state.layout.screenWidth,
    language: state.language.language,
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
  };
};

const mapDispatchToProps = {
  set_single_item,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCmpMobile);
