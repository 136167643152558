import React, { Fragment, useEffect, useState } from 'react';
import '../../assets/styles/component/mobile/walletcardmob.scss';
import { FcPlus } from 'react-icons/fc';
import { FaMinusCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { apiJson } from '../../Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import EmptyWallet from '../../assets/images/wallet_art.svg';
import moment from 'moment';
import { getCurrencyOfCountry } from '../../utils/utils';

const ReferCardMob = (props) => {
  const { t } = useTranslation();

  let message = '';
  const showError = () => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Fragment>
      <>
        {!props.referrals.length ? (
          <div className="wallet-empty-card-area">
            <div className="image-container">
              <img
                src={EmptyWallet}
                className="img-fluid"
                alt=""
                height="100%"
                width="100%"
              />
            </div>
            <div className="cart-text cart-head-text">
              {t('refer.empty-refer')}
            </div>
          </div>
        ) : (
          props.referrals.map((referral) => (
            <div
              key={referral.id}
              className="main-container-wallet-card-mobile px-3 mb-3 mt-3 pt-1 pb-2"
            >
              <div className="wallet-card-inner-container">
                <div className="main-row">
                  <FcPlus size={20} />

                  <div className="card-first-row">
                    <div className="left">
                      <div className="return-refund-label">
                        <span className="returned-or-refunded">
                          {t('refer.referral_cashback')}
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <span className="green-color">
                        {getCurrencyOfCountry(props.country) +
                          ' ' +
                          parseFloat(referral.cashback_amount || 0)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-second-row mt-1 pb-1">
                  <div className="left">
                    <span className="date-ret-ref label-for-balance">
                      {moment(new Date(referral.created_at)).format('lll')}
                    </span>
                  </div>
                  <div className="right">
                    <span className="balance-span label-for-balance">
                      {t('refer.status')}
                    </span>
                    <span className="balance-ammount">: {referral.status}</span>
                  </div>
                </div>
                <div className="card-third-row mt-3">
                  <span className="product-description">
                    {referral.referee_user}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
  };
};
export default connect(mapStateToProps, null)(ReferCardMob);
