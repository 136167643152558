import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { set_loading } from "../../redux/actions/LayoutAction";
import { set_addresses } from "../../redux/actions/SelectAddressAction";
import { ReactComponent as AddressLatLang } from "../../assets/icons/AddressLatLang.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PaymentMethodMob from "../../components/mobile/PaymentMethodMob";
import ProductDetailCard from "../../components/mobile/shared/ProductDetailCard";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { set_cart_items } from "../../redux/actions/CartAction";
import { useTranslation } from "react-i18next";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import "../../assets/styles/mobile/checkoutmob.scss";
import "react-toastify/dist/ReactToastify.css";
import { getMobileImage } from "../../utils/utils";
import HeaderMobile from "../../components/mobile/HeaderMobile";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from "react-phone-input-2";
import { Col, Row, Container } from "react-bootstrap";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const CheckoutMob = (props) => {
  let country_code =
    props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : props.country.toLowerCase() === "saudi arabia"
        ? "ksa"
        : "egypt";
  let message = "";
  let suggestme = 100 - (props.order?.createdAddress ? props.order?.createdAddress?.sub_total : 0);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({ falg: true, cost: 0 });

  const [redirect, setRedirect] = useState(false);
  const [card, setCard] = useState(true);
  const [cod, setCod] = useState(false);
  const { t } = useTranslation();
  const [cardData, setCardData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const redirectToHome = false;
  const [couponExpired, setCoupopnExpired] = useState(false);

  const [addressModalShow, setAddressModalShow] = React.useState(false);
  const [editAddressModalShow, setEditAddressModalShow] = React.useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [first_name, setFirstName] = useState("");
  const [first_name_err, setFirstNameError] = useState("");
  const [last_name, setLastName] = useState("-");
  const [contact, setContact] = useState("");
  const [contact_err, setContactError] = useState("");
  const [address1, setAddress1] = useState("");
  const [address1_err, setAddress1Error] = useState("");
  const [address2, setAddress2] = useState("");
  const [address2_err, setAddress2Error] = useState("");
  const [emirate, setEmirate] = useState("");
  const [emirate_err, setEmirateError] = useState("");
  const [addresses_modal_show, setAddressesModalShow] = useState(false);

  const [pickup_addresses, setPickupAddresses] = useState([]);
  const [customer_name, setCustomerName] = useState(props.user?.first_name_en ? props.user?.first_name_en + " " + props.user?.last_name_en : "");
  const [customer_contact, setCustomerContact] = useState("");
  const [is_delivery, setIsDelivery] = React.useState(true);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (
      wallet.falg &&
      parseFloat(wallet.cost) >=
      parseFloat(props.order.createdAddress?.grand_total)
    ) {
      setCod(true);
    } else if (
      wallet.falg &&
      parseFloat(wallet.cost) < parseFloat(props.grand_total)
    ) {
      setCard(true);
    }
    // eslint-disable-next-line
  }, [wallet.falg, props.grand_total]);

  useEffect(() => {
    props.set_loading(true);

    apiJson
      .checkout.pickupAddresses(props.countryId)
      .then(response => {
        if (response.data) {
          setPickupAddresses(response.data);
        }
      })

    if (props.auth) {
      apiJson.select.getAddress().then((response) => {
        props.set_loading(false);

        if (response.success) {
          props.set_addresses({
            address: response.data,
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

      setIsDelivery(props.order?.createdAddress?.is_delivery == 1 ? true : false);

      apiJson.cart.getCartItems(props.countryId).then((response) => {
        props.set_loading(false);
        if (response.success) {
          if (props.order?.createdAddress) {
            suggestme = 100 - props.order?.createdAddress?.sub_total;
          }
          else {
            suggestme = 100 - parseFloat(response.data.sub_total);
          }

          props.set_cart_items({
            cart_items: response.data,
            sub_total: props.order?.createdAddress ? props.order?.createdAddress.sub_total : response.data.sub_total,
            discount: props.order?.createdAddress ? props.order?.createdAddress.discount : response.data.discount,
            delivery_fee: props.order?.createdAddress ? props.order?.createdAddress.delivery_fee : "0",
            vat: props.order?.createdAddress ? props.order?.createdAddress.vat : response.data.vat,
            grand_total: props.order?.createdAddress ? props.order?.createdAddress.grand_total : response.data.sub_total,
          });
          // props.set_cart_items({ cart_items: response.data });
          setData(true);
        } else if (!response.status) {
          setData(true);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      if (localStorage.getItem("guest_user_address")) {
        // props.set_addresses({
        //   address: [
        //     JSON.parse(localStorage.getItem("guest_user_address"))
        //   ],
        // });
        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
        guest_user_address.default = true;

        props.set_addresses({
          address: [guest_user_address]
        });

        const request = {
          order: {
            address_id: guest_user_address.id,
            is_delivery: 1
          },
        };
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            setIsDelivery(resp.data.is_delivery == 1 ? true : false);
            if (resp.success) {
              props.set_cart_items({
                order: {
                  address: guest_user_address,
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                props.set_loading(false);
                if (cart_response.success) {
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                  setData(true);
                } else if (!cart_response.status) {
                  setData(true);
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  setData(true);
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
                setData(true);
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });

        apiJson.cart.getCartItems(props.countryId).then((response) => {
          props.set_loading(false);
          if (response.success) {
            if (props.order?.createdAddress) {
              suggestme = 100 - props.order?.createdAddress?.sub_total;
            }
            else {
              suggestme = 100 - parseFloat(response.data.sub_total);
            }

            props.set_cart_items({
              cart_items: response.data,
              sub_total: props.order?.createdAddress ? props.order?.createdAddress.sub_total : response.data.sub_total,
              discount: props.order?.createdAddress ? props.order?.createdAddress.discount : response.data.discount,
              delivery_fee: props.order?.createdAddress ? props.order?.createdAddress.delivery_fee : "0",
              vat: props.order?.createdAddress ? props.order?.createdAddress.vat : response.data.vat,
              grand_total: props.order?.createdAddress ? props.order?.createdAddress.grand_total : response.data.sub_total,
            });
            // props.set_cart_items({ cart_items: response.data });
            setData(true);
          } else if (!response.status) {
            setData(true);
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setData(true);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      }
      else {
        props.set_addresses({
          address: [],
        });
        // setIsDelivery(props.order?.createdAddress?.is_delivery == 1 ? true : false);
        setIsDelivery(true);

        apiJson.cart.getCartItems(props.countryId).then((response) => {
          props.set_loading(false);
          if (response.success) {
            if (props.order?.createdAddress) {
              suggestme = 100 - props.order?.createdAddress?.sub_total;
            }
            else {
              suggestme = 100 - parseFloat(response.data.sub_total);
            }

            props.set_cart_items({
              cart_items: response.data,
              sub_total: props.order?.createdAddress ? props.order?.createdAddress.sub_total : response.data.sub_total,
              discount: props.order?.createdAddress ? props.order?.createdAddress.discount : response.data.discount,
              delivery_fee: props.order?.createdAddress ? props.order?.createdAddress.delivery_fee : "0",
              vat: props.order?.createdAddress ? props.order?.createdAddress.vat : response.data.vat,
              grand_total: props.order?.createdAddress ? props.order?.createdAddress.grand_total : response.data.sub_total,
            });
            // props.set_cart_items({ cart_items: response.data });
            setData(true);
          } else if (!response.status) {
            setData(true);
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setData(true);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    setCustomerContact(props.address?.length ?
      props.address.filter(address => address.default == true).length ?
        props.address.filter(address => address.default == true)[0] ?
          props.address.filter(address => address.default == true)[0].contact_number
          :
          ""
        :
        ""
      :
      "")
  }, [props.address]);

  const handleSubmit = () => {
    if (!is_delivery) {
      if (!customer_name) {
        message = "Customer name is required.";
        showError();
        return;
      }
      if (!customer_contact || customer_contact == "971") {
        message = "Customer contact is required.";
        showError();
        return;
      }
    }

    if (is_delivery) {
      if (props.order?.createdAddress?.address) {
        if (card) {
          if (cardData.id) {
            setLoading(true);
            let data = [];
            if (cardData.id === -1) {
              data = {
                use_card:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : card,
                use_wallet: wallet.flag,
                cash_on_delivery:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : cod,
                card_number: cardData.card_number,
                expiry_date: cardData.expiry_date,
                cvv: cardData.cvv,
              };
            } else {
              data = {
                use_card:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : card,
                use_wallet: wallet.flag,
                cash_on_delivery:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : cod,
                payment_card_id: cardData.id,
              };
            }
            apiJson.checkout
              .placeOrder(
                {
                  payment: data,
                  customer_name: customer_name,
                  customer_contact: customer_contact
                },
                props.order?.id,
                props.countryId
              )
              .then((response) => {
                setLoading(false);
                if (response.success) {
                  if (localStorage.getItem("guest_user_address")) {
                    localStorage.removeItem("guest_user_address");
                  }
                  setRedirect(true);
                } else if (!response.status) {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
          } else {
            message = "Please add valid card details";
            showError();
          }
        } else {
          setLoading(true);
          apiJson.checkout
            .placeOrder(
              {
                payment: {
                  use_card:
                    wallet.falg &&
                      parseFloat(wallet.cost) >=
                      parseFloat(props.order.createdAddress.grand_total)
                      ? false
                      : card,
                  use_wallet: wallet.flag,
                  cash_on_delivery:
                    wallet.falg &&
                      parseFloat(wallet.cost) >=
                      parseFloat(props.order.createdAddress.grand_total)
                      ? false
                      : cod,
                },
                customer_name: customer_name,
                customer_contact: customer_contact
              },
              props.order?.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        }
      }
      else {
        message = "Select address to place order";
        showError();
      }
    }
    else {
      if (props.order?.createdAddress?.pickup_address) {
        if (card) {
          if (cardData.id) {
            setLoading(true);
            let data = [];
            if (cardData.id === -1) {
              data = {
                use_card:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : card,
                use_wallet: wallet.flag,
                cash_on_delivery:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : cod,
                card_number: cardData.card_number,
                expiry_date: cardData.expiry_date,
                cvv: cardData.cvv,
              };
            } else {
              data = {
                use_card:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : card,
                use_wallet: wallet.flag,
                cash_on_delivery:
                  wallet.falg &&
                    parseFloat(wallet.cost) >=
                    parseFloat(props.order.createdAddress.grand_total)
                    ? false
                    : cod,
                payment_card_id: cardData.id,
              };
            }
            apiJson.checkout
              .placeOrder(
                {
                  payment: data,
                  customer_name: customer_name,
                  customer_contact: customer_contact
                },
                props.order?.id,
                props.countryId
              )
              .then((response) => {
                setLoading(false);
                if (response.success) {
                  if (localStorage.getItem("guest_user_address")) {
                    localStorage.removeItem("guest_user_address");
                  }
                  setRedirect(true);
                } else if (!response.status) {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
          } else {
            message = "Please add valid card details";
            showError();
          }
        } else {
          setLoading(true);
          apiJson.checkout
            .placeOrder(
              {
                payment: {
                  use_card:
                    wallet.falg &&
                      parseFloat(wallet.cost) >=
                      parseFloat(props.order.createdAddress.grand_total)
                      ? false
                      : card,
                  use_wallet: wallet.flag,
                  cash_on_delivery:
                    wallet.falg &&
                      parseFloat(wallet.cost) >=
                      parseFloat(props.order.createdAddress.grand_total)
                      ? false
                      : cod,
                },
                customer_name: customer_name,
                customer_contact: customer_contact
              },
              props.order?.id,
              props.countryId
            )
            .then((response) => {
              setLoading(false);
              if (response.success) {
                if (localStorage.getItem("guest_user_address")) {
                  localStorage.removeItem("guest_user_address");
                }
                setRedirect(true);
              } else if (!response.status) {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                if (response.status >= 400 && response.status < 500) {
                  message = response.message
                    ? response.message
                    : response.errors
                      ? response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        }
      }
      else {
        message = "Select pickup address to place order";
        showError();
      }
    }
  };
  useEffect(() => {
    props.cart_items.cart_data_changed &&
      setDataChanged(props.cart_items?.cart_data_changed);

    props.cart_items.cart_data_changed &&
      apiJson.cart.updateCartReset(props.countryId);
    props.cart_items.cart_data_changed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          cart_data_changed: false,
        },
      });
    // eslint-disable-next-line
  }, [props.cart_items.cart_data_changed]);

  useEffect(() => {
    props.cart_items.expired_coupon_removed &&
      setCoupopnExpired(props.cart_items?.expired_coupon_removed);

    props.cart_items.expired_coupon_removed &&
      apiJson.cart.discountReset(props.countryId);
    props.cart_items.expired_coupon_removed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          expired_coupon_removed: false,
        },
      });
    // eslint-disable-next-line
  }, [props.cart_items.expired_coupon_removed]);

  const setDefaultAddress = (id) => {
    if (localStorage.getItem("access_token")) {
      props.set_loading(true);
      setData(false);
      apiJson.select.setDefault(id).then((response) => {
        if (response.success) {
          props.set_addresses({
            address: props.address.map((item) =>
              item.id === response.data.id
                ? { ...item, default: true }
                : item.default
                  ? { ...item, default: false }
                  : item
            ),
          });
          const request = {
            order: {
              address_id: response.data.id,
              is_delivery: 1
            },
          };
          apiJson.select
            .varifyAdress(request, props.countryId)
            .then((resp) => {
              if (resp.success) {
                props.set_cart_items({
                  order: {
                    address: response.data,
                    id: resp.data.id,
                    createdAddress: resp.data,
                  },
                });
                message = "Address changed successfully.";
                showSuccess();
                setAddressesModalShow(false);
                apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                  props.set_loading(false);
                  if (cart_response.success) {
                    props.set_cart_items({
                      cart_items: cart_response.data,
                      sub_total: resp.data.sub_total,
                      discount: resp.data.discount,
                      delivery_fee: resp.data.delivery_fee,
                      vat: resp.data.vat,
                      grand_total: resp.data.grand_total,
                    });
                    setData(true);
                  } else if (!cart_response.status) {
                    setData(true);
                    message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  } else {
                    setData(true);
                    if (cart_response.status >= 400 && cart_response.status < 500) {
                      message = cart_response.message
                        ? cart_response.message
                        : cart_response.errors
                          ? cart_response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = cart_response.message
                        ? cart_response.message !== ""
                          ? cart_response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                  setData(true);
                });
              } else if (!resp.status) {
                message = t("errors.wrong");
                showError();
              } else {
                if (resp.status >= 400 && resp.status < 500) {
                  message = resp.message
                    ? resp.message
                    : resp.errors
                      ? resp.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = resp.message
                    ? resp.message !== ""
                      ? resp.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
    else {
      if (localStorage.getItem("guest_user_address")) {
        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

        props.set_addresses({
          address: props.address.map((item) =>
            item.id === guest_user_address.id
              ? { ...item, default: true }
              : item.default
                ? { ...item, default: false }
                : item
          ),
        });

        const request = {
          order: {
            address_id: guest_user_address.id,
            is_delivery: 1
          },
        };
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            if (resp.success) {
              props.set_cart_items({
                order: {
                  address: guest_user_address,
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              message = "Address changed successfully.";
              showSuccess();
              setAddressesModalShow(false);
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                props.set_loading(false);
                if (cart_response.success) {
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                  setData(true);
                } else if (!cart_response.status) {
                  setData(true);
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  setData(true);
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
                setData(true);
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      }
    }
  }

  const onAddAddressClicked = () => {
    setProductToEdit(null);
    setFirstName("");
    setContact("");
    setAddress1("");
    setAddress2("");
    setEmirate("");
    setAddressModalShow(true);
  }

  const onAddressInputFieldChanged = (e) => {
    if (e.target.name == "first_name") {
      setFirstName(e.target.value);
    }
    else if (e.target.name == "address1") {
      setAddress1(e.target.value);
    }
    else if (e.target.name == "address2") {
      setAddress2(e.target.value);
    }
    else if (e.target.name == "emirate") {
      setEmirate(e.target.value);
    }
  }

  const handleValidation = () => {
    var isValid = true;

    if (!first_name) {
      setFirstNameError("*Full name is required.");
      isValid = false;
    }
    else {
      setFirstName("");
    }

    if (!contact) {
      setContactError("*Contact is required.");
      isValid = false;
    }
    else {
      setContactError("");
    }

    if (!address1) {
      setAddress1Error("*Building name/no. is required.");
      isValid = false;
    }
    else {
      setAddress1Error("");
    }

    if (!address2) {
      setAddress2Error("*Flat/Apartment or Villa no. is required.");
      isValid = false;
    }
    else {
      setAddress2Error("");
    }

    if (!emirate) {
      setEmirateError("*Emirate is required.");
      isValid = false;
    }
    else {
      setEmirateError("");
    }

    return isValid;
  }

  const saveAddressBtnClicked = () => {
    if (handleValidation()) {
      let tempLabel = "Home";

      apiJson.select
        .addAdress({
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: emirate,
            address1: address1,
            address2: address2,
            latitude: "-",
            longitude: "-",
            label: tempLabel,
            first_name: first_name,
            last_name: last_name,
            contact_number: "+" + contact,
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_loading(true);

            if (props.auth) {
              apiJson.select.setDefault(response.data.id).then((response) => {
                if (response.success) {
                  // props.set_addresses({
                  //   address: props.address.map((item) =>
                  //     item.id === response.data.id
                  //       ? { ...item, default: true }
                  //       : item.default
                  //         ? { ...item, default: false }
                  //         : item
                  //   ),
                  // });
                  const request = {
                    order: {
                      address_id: response.data.id,
                      is_delivery: 1
                    },
                  };
                  apiJson.select
                    .varifyAdress(request, props.countryId)
                    .then((resp) => {
                      if (resp.success) {
                        props.set_cart_items({
                          order: {
                            address: response.data,
                            id: resp.data.id,
                            createdAddress: resp.data,
                          },
                        });
                        apiJson.select.getAddress().then((response) => {
                          props.set_loading(false);

                          if (response.success) {
                            props.set_addresses({
                              address: response.data,
                            });
                          } else if (!response.status) {
                            // eslint-disable-next-line
                            message = t("errors.wrong");
                            showError();
                          } else {
                            if (response.status >= 400 && response.status < 500) {
                              message = response.message
                                ? response.message
                                : response.errors
                                  ? response.errors
                                  : t("errors.wrong");
                              showError();
                            } else {
                              message = response.message
                                ? response.message !== ""
                                  ? response.message
                                  : t("errors.wrong")
                                : t("errors.wrong");
                              showError();
                            }
                          }
                          props.set_loading(false);
                        });
                        apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                          props.set_loading(false);
                          if (cart_response.success) {
                            props.set_cart_items({
                              cart_items: cart_response.data,
                              sub_total: resp.data.sub_total,
                              discount: resp.data.discount,
                              delivery_fee: resp.data.delivery_fee,
                              vat: resp.data.vat,
                              grand_total: resp.data.grand_total,
                            });
                            setData(true);
                          } else if (!cart_response.status) {
                            setData(true);
                            message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          } else {
                            setData(true);
                            if (cart_response.status >= 400 && cart_response.status < 500) {
                              message = cart_response.message
                                ? cart_response.message
                                : cart_response.errors
                                  ? cart_response.errors
                                  : t("errors.wrong");
                              showError();
                            } else {
                              message = cart_response.message
                                ? cart_response.message !== ""
                                  ? cart_response.message
                                  : t("errors.wrong")
                                : t("errors.wrong");
                              showError();
                            }
                          }
                          setData(true);
                        });
                      } else if (!resp.status) {
                        message = t("errors.wrong");
                        showError();
                      } else {
                        if (resp.status >= 400 && resp.status < 500) {
                          message = resp.message
                            ? resp.message
                            : resp.errors
                              ? resp.errors
                              : t("errors.wrong");
                          showError();
                        } else {
                          message = resp.message
                            ? resp.message !== ""
                              ? resp.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        }
                      }
                    });
                } else if (!response.status) {
                  message = t("errors.wrong");
                  showError();
                } else {
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
            } else {
              localStorage.setItem("guest_user_address", JSON.stringify(response.data));

              var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
              guest_user_address.default = true;

              props.set_addresses({
                address: [guest_user_address]
              });

              const request = {
                order: {
                  address_id: guest_user_address.id,
                  is_delivery: 1
                },
              };
              apiJson.select
                .varifyAdress(request, props.countryId)
                .then((resp) => {
                  if (resp.success) {
                    props.set_cart_items({
                      order: {
                        address: guest_user_address,
                        id: resp.data.id,
                        createdAddress: resp.data,
                      },
                    });
                    apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                      props.set_loading(false);
                      if (cart_response.success) {
                        props.set_cart_items({
                          cart_items: cart_response.data,
                          sub_total: resp.data.sub_total,
                          discount: resp.data.discount,
                          delivery_fee: resp.data.delivery_fee,
                          vat: resp.data.vat,
                          grand_total: resp.data.grand_total,
                        });
                        setData(true);
                      } else if (!cart_response.status) {
                        setData(true);
                        message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                          ? cart_response.message !== ""
                            ? cart_response.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      } else {
                        setData(true);
                        if (cart_response.status >= 400 && cart_response.status < 500) {
                          message = cart_response.message
                            ? cart_response.message
                            : cart_response.errors
                              ? cart_response.errors
                              : t("errors.wrong");
                          showError();
                        } else {
                          message = cart_response.message
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        }
                      }
                      setData(true);
                    });
                  } else if (!resp.status) {
                    message = t("errors.wrong");
                    showError();
                  } else {
                    if (resp.status >= 400 && resp.status < 500) {
                      message = resp.message
                        ? resp.message
                        : resp.errors
                          ? resp.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = resp.message
                        ? resp.message !== ""
                          ? resp.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                });
            }
            setAddressModalShow(false);
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
  }

  const onEditAddressClicked = (address) => {
    setProductToEdit(address);
    setFirstName(address.first_name);
    setContact(address.contact_number);
    setAddress1(address.address1);
    setAddress2(address.address2);
    setEmirate(address.state);
    setEditAddressModalShow(true);
  }

  const onEditAdressClosed = () => {
    setProductToEdit(null);
    setFirstName("");
    setContact("");
    setAddress1("");
    setAddress2("");
    setEmirate("");
    setEditAddressModalShow(false);
  }

  const editAddressBtnClicked = () => {
    if (handleValidation()) {
      let tempLabel = "Home";

      apiJson.select
        .updateAddress(productToEdit.id, {
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: emirate,
            address1: address1,
            address2: address2,
            latitude: "-",
            longitude: "-",
            label: tempLabel,
            first_name: first_name,
            last_name: last_name,
            contact_number: "+" + contact,
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_loading(true);

            if (props.auth) {
              if (response.data.default) {
                const request = {
                  order: {
                    address_id: response.data.id,
                    is_delivery: 1
                  },
                };
                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((resp) => {
                    if (resp.success) {
                      props.set_cart_items({
                        order: {
                          address: response.data,
                          id: resp.data.id,
                          createdAddress: resp.data,
                        },
                      });
                      apiJson.select.getAddress().then((response) => {
                        props.set_loading(false);

                        if (response.success) {
                          props.set_addresses({
                            address: response.data,
                          });
                          setEditAddressModalShow(false);
                        } else if (!response.status) {
                          // eslint-disable-next-line
                          message = t("errors.wrong");
                          showError();
                        } else {
                          if (response.status >= 400 && response.status < 500) {
                            message = response.message
                              ? response.message
                              : response.errors
                                ? response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = response.message
                              ? response.message !== ""
                                ? response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        props.set_loading(false);
                      });
                      apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                        props.set_loading(false);
                        if (cart_response.success) {
                          props.set_cart_items({
                            cart_items: cart_response.data,
                            sub_total: resp.data.sub_total,
                            discount: resp.data.discount,
                            delivery_fee: resp.data.delivery_fee,
                            vat: resp.data.vat,
                            grand_total: resp.data.grand_total,
                          });
                          setData(true);
                        } else if (!cart_response.status) {
                          setData(true);
                          message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        } else {
                          setData(true);
                          if (cart_response.status >= 400 && cart_response.status < 500) {
                            message = cart_response.message
                              ? cart_response.message
                              : cart_response.errors
                                ? cart_response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = cart_response.message
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        setData(true);
                      });
                    } else if (!resp.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (resp.status >= 400 && resp.status < 500) {
                        message = resp.message
                          ? resp.message
                          : resp.errors
                            ? resp.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = resp.message
                          ? resp.message !== ""
                            ? resp.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                apiJson.select.getAddress().then((response) => {
                  props.set_loading(false);

                  if (response.success) {
                    props.set_addresses({
                      address: response.data,
                    });
                    setEditAddressModalShow(false);
                  } else if (!response.status) {
                    // eslint-disable-next-line
                    message = t("errors.wrong");
                    showError();
                  } else {
                    if (response.status >= 400 && response.status < 500) {
                      message = response.message
                        ? response.message
                        : response.errors
                          ? response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = response.message
                        ? response.message !== ""
                          ? response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                  props.set_loading(false);
                });
              }
            } else {
              localStorage.setItem("guest_user_address", JSON.stringify(response.data));

              if (localStorage.getItem("guest_user_address")) {
                var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

                props.set_addresses({
                  address: [guest_user_address]
                });

                const request = {
                  order: {
                    address_id: guest_user_address.id,
                    is_delivery: 1
                  },
                };
                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((resp) => {
                    if (resp.success) {
                      props.set_cart_items({
                        order: {
                          address: guest_user_address,
                          id: resp.data.id,
                          createdAddress: resp.data,
                        },
                      });
                      apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                        props.set_loading(false);
                        if (cart_response.success) {
                          props.set_cart_items({
                            cart_items: cart_response.data,
                            sub_total: resp.data.sub_total,
                            discount: resp.data.discount,
                            delivery_fee: resp.data.delivery_fee,
                            vat: resp.data.vat,
                            grand_total: resp.data.grand_total,
                          });
                          setData(true);
                        } else if (!cart_response.status) {
                          setData(true);
                          message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        } else {
                          setData(true);
                          if (cart_response.status >= 400 && cart_response.status < 500) {
                            message = cart_response.message
                              ? cart_response.message
                              : cart_response.errors
                                ? cart_response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = cart_response.message
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        setData(true);
                      });
                    } else if (!resp.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (resp.status >= 400 && resp.status < 500) {
                        message = resp.message
                          ? resp.message
                          : resp.errors
                            ? resp.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = resp.message
                          ? resp.message !== ""
                            ? resp.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                props.set_addresses({
                  address: [],
                });
              }
              setEditAddressModalShow(false);
            }
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
  }

  const onChangeAddressesClicked = () => {
    setAddressesModalShow(true);
  }

  const onPickupAddressSelected = (id) => {
    const request = {
      order: {
        pickup_address_id: id,
        is_delivery: 0
      },
    };
    apiJson.select
      .varifyAdress(request, props.countryId)
      .then((resp) => {
        if (resp.success) {
          props.set_cart_items({
            order: {
              address: null,
              id: resp.data.id,
              createdAddress: resp.data,
            },
          });

          apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
            props.set_loading(false);
            if (cart_response.success) {
              props.set_cart_items({
                cart_items: cart_response.data,
                sub_total: resp.data.sub_total,
                discount: resp.data.discount,
                delivery_fee: resp.data.delivery_fee,
                vat: resp.data.vat,
                grand_total: resp.data.grand_total,
              });
              setData(true);
            } else if (!cart_response.status) {
              setData(true);
              message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                ? cart_response.message !== ""
                  ? cart_response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            } else {
              setData(true);
              if (cart_response.status >= 400 && cart_response.status < 500) {
                message = cart_response.message
                  ? cart_response.message
                  : cart_response.errors
                    ? cart_response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = cart_response.message
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
            setData(true);
          });
        } else if (!resp.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (resp.status >= 400 && resp.status < 500) {
            message = resp.message
              ? resp.message
              : resp.errors
                ? resp.errors
                : t("errors.wrong");
            showError();
          } else {
            message = resp.message
              ? resp.message !== ""
                ? resp.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }

  const onHomeDeliveryClicked = (e) => {
    e.preventDefault();

    if (localStorage.getItem("guest_user_address")) {
      var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
      guest_user_address.default = true;

      props.set_addresses({
        address: [guest_user_address]
      });
      const request = {
        order: {
          address_id: guest_user_address.id,
          is_delivery: 1
        },
      };
      apiJson.select
        .varifyAdress(request, props.countryId)
        .then((resp) => {
          if (resp.success) {
            setIsDelivery(resp.data.is_delivery == 1 ? true : false);
            props.set_cart_items({
              order: {
                address: guest_user_address,
                id: resp.data.id,
                createdAddress: resp.data,
              },
            });
            apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
              if (cart_response.success) {
                props.set_cart_items({
                  cart_items: cart_response.data,
                  sub_total: resp.data.sub_total,
                  discount: resp.data.discount,
                  delivery_fee: resp.data.delivery_fee,
                  vat: resp.data.vat,
                  grand_total: resp.data.grand_total,
                });
              } else if (!cart_response.status) {
                message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                setData(true);
                if (cart_response.status >= 400 && cart_response.status < 500) {
                  message = cart_response.message
                    ? cart_response.message
                    : cart_response.errors
                      ? cart_response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = cart_response.message
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
              setData(true);
            });
          } else if (!resp.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (resp.status >= 400 && resp.status < 500) {
              message = resp.message
                ? resp.message
                : resp.errors
                  ? resp.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = resp.message
                ? resp.message !== ""
                  ? resp.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
    else {
      var defaultAddress = props.address?.filter(address => address.default == true);

      if (defaultAddress.length) {
        const request = {
          order: {
            address_id: defaultAddress[0]?.id,
            is_delivery: 1
          },
        };

        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            if (resp.success) {
              props.set_cart_items({
                order: {
                  address: defaultAddress[0],
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                if (cart_response.success) {
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                } else if (!cart_response.status) {
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      }
    }

    setIsDelivery(true);
  }

  const onClickCollectClicked = (e) => {
    e.preventDefault();

    const request = {
      order: {
        pickup_address_id: pickup_addresses[0]?.id,
        is_delivery: 0
      },
    };
    apiJson.select
      .varifyAdress(request, props.countryId)
      .then((resp) => {
        if (resp.success) {
          props.set_cart_items({
            order: {
              address: null,
              id: resp.data.id,
              createdAddress: resp.data,
            },
          });

          apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
            props.set_loading(false);
            if (cart_response.success) {
              props.set_cart_items({
                cart_items: cart_response.data,
                sub_total: resp.data.sub_total,
                discount: resp.data.discount,
                delivery_fee: resp.data.delivery_fee,
                vat: resp.data.vat,
                grand_total: resp.data.grand_total,
              });
              setData(true);
            } else if (!cart_response.status) {
              setData(true);
              message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                ? cart_response.message !== ""
                  ? cart_response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            } else {
              setData(true);
              if (cart_response.status >= 400 && cart_response.status < 500) {
                message = cart_response.message
                  ? cart_response.message
                  : cart_response.errors
                    ? cart_response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = cart_response.message
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
            setData(true);
          });
        } else if (!resp.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (resp.status >= 400 && resp.status < 500) {
            message = resp.message
              ? resp.message
              : resp.errors
                ? resp.errors
                : t("errors.wrong");
            showError();
          } else {
            message = resp.message
              ? resp.message !== ""
                ? resp.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

    setIsDelivery(false);
  }

  return (
    <>
      {/* {props.width === "sm" &&
        (!props.order.id ? (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/cart"}
          />
        ) : (
          <> */}
      <MobileLayoutRenderer
        NavbarTop={false}
        BackBtn={true}
        CancelBtn={false}
        NavbarBottom={true}
        SearchIcon={true}
      >
        <div className="home-mob">
          <HeaderMobile />
        </div>
        <div className="checkout-mobile-main">
          {!data ? (
            <></>
          ) : (
            <div className="checkout-inner-main">

              <Modal
                show={addresses_modal_show}
                onHide={() => setAddressesModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="customer-checkout-desktop"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <p className="m-0 ship-to-heading">Ship To</p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 py-2 addresses-popup">
                  {props.address.map(
                    (address) =>
                      (address.country.toLowerCase() ===
                        props.country.toLowerCase() ||
                        address.country.toLowerCase() === country_code) && !address.default && (
                        <div
                          className="d-flex bg-white address-card py-2 justify-content-between align-items-center"
                          key={address.id}
                        >
                          <div
                            onClick={() => setDefaultAddress(address.id)}
                            className="d-flex align-items-center w-100">
                            <div>
                              <p className="my-0 font-weight-bold user_name">{address.first_name}</p>
                              <p className="my-0 info">{address.address1} - {address.address2}</p>
                              <p className="my-0 info">Phone Number:&nbsp;{address.contact_number}</p>
                            </div>
                          </div>
                          {
                            localStorage.getItem("guest_user_address") ?
                              <></>
                              // <div
                              //   onClick={() => onEditAddressClicked(address)}
                              //   className="view-edit-btn">
                              //   <p className="m-0">View / Edit</p>
                              // </div>
                              :
                              <div
                                onClick={() => onEditAddressClicked(address)}
                                className="view-edit-btn">
                                <p className="m-0">View / Edit</p>
                              </div>
                          }
                        </div>
                      )
                  )}
                </Modal.Body>
                <Modal.Footer
                  onClick={onAddAddressClicked}
                  style={{
                    cursor: "pointer"
                  }}
                  className="justify-content-start">
                  <p className="m-0">{t("select-shipping-address.add-new-address")}</p>
                </Modal.Footer>
              </Modal>

              <Modal
                show={addressModalShow}
                onHide={() => setAddressModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-address-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    ADD ADDRESS
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 py-2">
                  <div className="form-group">
                    <label className="mb-0">Full Name:</label>
                    <input name="first_name" type="text" className="form-control" placeholder="Full Name"
                      onChange={onAddressInputFieldChanged} value={first_name} />
                    <p className="m-0 text-danger">{first_name_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Contact:</label>
                    <PhoneInput
                      inputProps={{ name: "", maxLength: 17 }}
                      placeholder={"Mobile Number"}
                      countryCodeEditable={false}
                      onlyCountries={["ae"]}
                      country={"ae"}
                      value={contact}
                      // onBlur={() => setFieldTouched("phone", true)}
                      onChange={(val) => setContact(val)}
                      enableLongNumbers={false}
                      className="w-100"
                    />
                    <p className="m-0 text-danger">{contact_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Building name/no.:</label>
                    <input name="address1" type="text" className="form-control" placeholder="Building Name/No."
                      onChange={onAddressInputFieldChanged} value={address1} />
                    <p className="m-0 text-danger">{address1_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Floor, Apartment, Villa no.:</label>
                    <input name="address2" type="text" className="form-control" placeholder="Floor, Apartment, Villa no."
                      onChange={onAddressInputFieldChanged} value={address2} />
                    <p className="m-0 text-danger">{address2_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Emirate:</label>
                    <select
                      name="emirate"
                      className="w-100 form-control"
                      onChange={onAddressInputFieldChanged}
                      value={emirate}
                    >
                      <option value="">--- Select emirate ---</option>
                      {
                        props.countryId == 1 ?
                          <>
                            <option value="Dubai">Dubai</option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Sharjah">Sharjah</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Umm Al Quwain">Umm Al Quwain</option>
                            <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                            <option value="Fujairah">Fujairah</option>
                          </>
                          :
                          <></>
                      }
                    </select>
                    <p className="m-0 text-danger">{emirate_err}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={saveAddressBtnClicked}
                    className="save-address">Save Address</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={editAddressModalShow}
                onHide={onEditAdressClosed}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-address-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    EDIT ADDRESS
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 py-2">
                  <div className="form-group">
                    <label className="mb-0">Full Name:</label>
                    <input name="first_name" type="text" className="form-control" placeholder="Full Name"
                      onChange={onAddressInputFieldChanged} value={first_name} />
                    <p className="m-0 text-danger">{first_name_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Contact:</label>
                    <PhoneInput
                      inputProps={{ name: "", maxLength: 17 }}
                      placeholder={"Mobile Number"}
                      countryCodeEditable={false}
                      onlyCountries={["ae"]}
                      country={"ae"}
                      value={contact}
                      // onBlur={() => setFieldTouched("phone", true)}
                      onChange={(val) => setContact(val)}
                      enableLongNumbers={false}
                      className="w-100"
                    />
                    <p className="m-0 text-danger">{contact_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Building name/no.:</label>
                    <input name="address1" type="text" className="form-control" placeholder="Building Name/No."
                      onChange={onAddressInputFieldChanged} value={address1} />
                    <p className="m-0 text-danger">{address1_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Floor, Apartment, Villa no.:</label>
                    <input name="address2" type="text" className="form-control" placeholder="Floor, Apartment, Villa no."
                      onChange={onAddressInputFieldChanged} value={address2} />
                    <p className="m-0 text-danger">{address2_err}</p>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">Emirate:</label>
                    <select
                      name="emirate"
                      className="w-100 form-control"
                      onChange={onAddressInputFieldChanged}
                      value={emirate}
                    >
                      <option value="">--- Select emirate ---</option>
                      {
                        props.countryId == 1 ?
                          <>
                            <option value="Dubai">Dubai</option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Sharjah">Sharjah</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Umm Al Quwain">Umm Al Quwain</option>
                            <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                            <option value="Fujairah">Fujairah</option>
                          </>
                          :
                          <></>
                      }
                    </select>
                    <p className="m-0 text-danger">{emirate_err}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={editAddressBtnClicked}
                    className="save-address">Save Address</Button>
                </Modal.Footer>
              </Modal>

              {/* <div className="first-container">
                <div className="fc-first-row">
                  <label className="ship-to">
                    {t("checkout-mobile.ship-to")}
                  </label>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/select-address"
                    }
                  >
                    {" "}
                    <label className="change-address">
                      {t("checkout-mobile.change-address")}
                    </label>
                  </Link>
                </div>
                <div className="fc-second-row">
                  <div className="fc-sr-inner">
                    <AddressLatLang
                      style={{ width: "18px", height: "18px" }}
                      className="inner-icon"
                    />
                    <label className="home-label text-capitalize">
                      {props.order?.address?.label}
                    </label>
                  </div>
                  <span className="name text-capitalize">
                    {`${props.order?.address?.first_name}`}
                  </span>
                  <span className="home-address">
                    {props.order?.address?.address1}
                  </span>
                  <div className="phone-number">
                    <span className="phone">
                      {props.order?.address?.contact_number}{" "}
                    </span>
                    <CheckCircleIcon
                      style={{
                        color: "green",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                </div>
              </div> */}
              {
                props.cart_items?.line_items?.filter(line_item => line_item.orderable?.pickup_allowed == true)?.length == props.cart_items?.line_items?.length ?
                  <Row
                    noGutters={true}
                    className="shipping-options shipping-row margin-bottom-4 br-2"
                  >
                    <Col
                      xs={12}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <button
                        onClick={onClickCollectClicked}
                        className={`collect-button card-button ${is_delivery ? "gray-button" : ""}`}
                      >
                        <p className="title">
                          {
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["click-and-collect"]
                              :
                              ar_translation["my-account-logged-in"]["click-and-collect"]
                          }
                        </p>
                      </button>
                      <button
                        onClick={onHomeDeliveryClicked}
                        className={`collect-button cash-button ${is_delivery ? "" : "gray-button"}`}
                      >
                        <p className="title">
                          {
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["home-delivery"]
                              :
                              ar_translation["my-account-logged-in"]["home-delivery"]
                          }
                        </p>
                      </button>
                    </Col>
                  </Row>
                  :
                  <></>
              }

              {
                props.cart_items?.line_items?.filter(line_item => line_item.orderable?.pickup_allowed == true)?.length == props.cart_items?.line_items?.length ?
                  is_delivery ?
                    <div className={
                      "first-container"
                      +
                      (
                        props.address.length && props.address.filter(address => address.default == true).length ?
                          " inactive"
                          :
                          " active"
                      )
                    }>

                      <div className="addresses-section">
                        <label className="address-label">
                          {
                            props.address?.length ?
                              props.language == "en" ?
                                en_translation["my-account-logged-in"]["your-shipping-address"]
                                :
                                ar_translation["my-account-logged-in"]["your-shipping-address"]
                              :
                              props.language == "en" ?
                                en_translation["my-account-logged-in"]["enter-your-shipping-address"]
                                :
                                ar_translation["my-account-logged-in"]["enter-your-shipping-address"]
                          }
                        </label>
                        <div className="card addresses-card mb-3">
                          <div className="card-body p-0">
                            {props.address.map(
                              (address) =>
                                (address.country.toLowerCase() ===
                                  props.country.toLowerCase() ||
                                  address.country.toLowerCase() === country_code) && address.default && (
                                  <div
                                    className="d-flex bg-white address-card p-2 justify-content-between align-items-center"
                                    key={address.id}
                                  // style={{
                                  //   border: address.default ?
                                  //     "1px solid #3866df"
                                  //     :
                                  //     "1px solid rgba(0,0,0,.125)"
                                  // }}
                                  >
                                    <div
                                      onClick={() => {
                                        if (localStorage.getItem("guest_user_address")) {
                                          onAddAddressClicked();
                                        }
                                        else {
                                          onEditAddressClicked(address);
                                        }
                                      }}
                                      className="d-flex align-items-center">
                                      {/* <div
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    borderRadius: "50%",
                                    border: "2px solid #742013",
                                    background: address.default ? "#742013" : "white"
                                  }}
                                ></div> */}
                                      <div>
                                        <p className="my-0 user_name">{address.first_name}</p>
                                        <p className="my-0 info">{address.address1} - {address.address2}</p>
                                        <p className="my-0 info">Phone Number: {address.contact_number}</p>
                                      </div>
                                    </div>
                                    {
                                      localStorage.getItem("guest_user_address") ?
                                        <div
                                          onClick={onAddAddressClicked}
                                          className="view-edit-btn text-center mt-1">
                                          <p className="m-0">Change</p>
                                        </div>
                                        :
                                        <div>
                                          <div
                                            onClick={() => onEditAddressClicked(address)}
                                            className="view-edit-btn">
                                            <p className="m-0">View / Edit</p>
                                          </div>
                                          {
                                            props.address?.length > 1 ?
                                              <div
                                                onClick={onChangeAddressesClicked}
                                                className="view-edit-btn text-center mt-1">
                                                <p className="m-0">Change</p>
                                              </div>
                                              :
                                              <></>
                                          }
                                        </div>
                                    }
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={onAddAddressClicked}
                        className="text-center add-address-btn">
                        <p className="my-0">{t("select-shipping-address.add-new-address")}</p>
                      </div>
                    </div>
                    :
                    <div className="pickup-addresses">
                      <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                        {
                          props.language == "en" ?
                            en_translation["my-account-logged-in"]["select-pickup-address"]
                            :
                            ar_translation["my-account-logged-in"]["select-pickup-address"]
                        }
                      </div>
                      {
                        pickup_addresses?.map(address => {
                          return (
                            <div
                              className={
                                "pickup-address" + (
                                  props.order.createdAddress?.pickup_address?.id == address.id ?
                                    " active"
                                    :
                                    ""
                                )
                              }
                              onClick={() => onPickupAddressSelected(address.id)}
                            >
                              {
                                props.order.createdAddress?.pickup_address?.id == address.id ?
                                  <BsCheckCircle size={20} color="#742013" />
                                  :
                                  <BsCircle color="gray" />
                              }
                              <p>{address.store_address}</p>
                            </div>
                          )
                        })
                      }
                      <div className="customer-info">
                        <div className="payment-method-text mt-4 mb-2 text-uppercase font-weight-bold">
                          {
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["customer-info"]
                              :
                              ar_translation["my-account-logged-in"]["customer-info"]
                          }
                        </div>
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <label className="mb-0">{
                              props.language == "en" ?
                                en_translation["my-account-logged-in"]["customer-name"]
                                :
                                ar_translation["my-account-logged-in"]["customer-name"]
                            }</label>
                            <input
                              type="text"
                              value={customer_name}
                              placeholder="Customer Name"
                              onChange={(e) => setCustomerName(e.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label className="mb-0">{
                              props.language == "en" ?
                                en_translation["my-account-logged-in"]["customer-contact"]
                                :
                                ar_translation["my-account-logged-in"]["customer-contact"]
                            }</label>
                            <PhoneInput
                              inputProps={{ name: "customer_contact", maxLength: 17 }}
                              placeholder={"Customer Contact"}
                              countryCodeEditable={false}
                              onlyCountries={["ae"]}
                              country={"ae"}
                              value={customer_contact}
                              onChange={(val) => setCustomerContact(val)}
                              enableLongNumbers={false}
                              inputStyle={{
                                width: "100%"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  :
                  <div className={
                    "first-container"
                    +
                    (
                      props.address.length && props.address.filter(address => address.default == true).length ?
                        " inactive"
                        :
                        " active"
                    )
                  }>

                    <div className="addresses-section">
                      <label className="address-label">
                        {
                          props.address?.length ?
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["your-shipping-address"]
                              :
                              ar_translation["my-account-logged-in"]["your-shipping-address"]
                            :
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["enter-your-shipping-address"]
                              :
                              ar_translation["my-account-logged-in"]["enter-your-shipping-address"]
                        }
                      </label>
                      <div className="card addresses-card mb-3">
                        <div className="card-body p-0">
                          {props.address.map(
                            (address) =>
                              (address.country.toLowerCase() ===
                                props.country.toLowerCase() ||
                                address.country.toLowerCase() === country_code) && address.default && (
                                <div
                                  className="d-flex bg-white address-card p-2 justify-content-between align-items-center"
                                  key={address.id}
                                // style={{
                                //   border: address.default ?
                                //     "1px solid #3866df"
                                //     :
                                //     "1px solid rgba(0,0,0,.125)"
                                // }}
                                >
                                  <div
                                    onClick={() => {
                                      if (localStorage.getItem("guest_user_address")) {
                                        onAddAddressClicked();
                                      }
                                      else {
                                        onEditAddressClicked(address);
                                      }
                                    }}
                                    className="d-flex align-items-center">
                                    {/* <div
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  borderRadius: "50%",
                                  border: "2px solid #742013",
                                  background: address.default ? "#742013" : "white"
                                }}
                              ></div> */}
                                    <div>
                                      <p className="my-0 user_name">{address.first_name}</p>
                                      <p className="my-0 info">{address.address1} - {address.address2}</p>
                                      <p className="my-0 info">Phone Number: {address.contact_number}</p>
                                    </div>
                                  </div>
                                  {
                                    localStorage.getItem("guest_user_address") ?
                                      <div
                                        onClick={onAddAddressClicked}
                                        className="view-edit-btn text-center mt-1">
                                        <p className="m-0">Change</p>
                                      </div>
                                      :
                                      <div>
                                        <div
                                          onClick={() => onEditAddressClicked(address)}
                                          className="view-edit-btn">
                                          <p className="m-0">View / Edit</p>
                                        </div>
                                        {
                                          props.address?.length > 1 ?
                                            <div
                                              onClick={onChangeAddressesClicked}
                                              className="view-edit-btn text-center mt-1">
                                              <p className="m-0">Change</p>
                                            </div>
                                            :
                                            <></>
                                        }
                                      </div>
                                  }
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={onAddAddressClicked}
                      className="text-center add-address-btn">
                      <p className="my-0">{t("select-shipping-address.add-new-address")}</p>
                    </div>
                  </div>
              }

              <div className=" second-container-payment-method">
                <PaymentMethodMob
                  card={(val) => setCard(val)}
                  cod={(val) => setCod(val)}
                  wallet={(val) => setWallet(val)}
                  cardData={(data) => setCardData(data)}
                  is_delivery={is_delivery}
                />
              </div>
              {dataChanged && (
                <div className="data-change">
                  <div className="w-80 ">
                    <div>{t("data-changed.quantity-changed")}</div>
                    <div className="mt-1">
                      {t("data-changed.prices-changed")}
                    </div>
                    <div className="mt-1">
                      {t("data-changed.review-cart")}
                    </div>
                  </div>
                  <div className="w-8  dismiss-border dismiss m-auto">
                    <span
                      className="pointer"
                      onClick={() => setDataChanged(false)}
                    >
                      {" "}
                      {t("data-changed.dismiss")}
                    </span>
                  </div>
                </div>
              )}
              {couponExpired && (
                <div className="data-change">
                  <div className="w-90 ">
                    <div>{t("coupon-expire.coupon-expired")}</div>

                    <div className="mt-1">
                      {t("coupon-expire.review-cart")}
                    </div>
                  </div>
                  <div className="w-8  dismiss-border dismiss m-auto">
                    <span
                      className="pointer"
                      onClick={() => setCoupopnExpired(false)}
                    >
                      {" "}
                      {t("coupon-expire.dismiss")}
                    </span>
                  </div>
                </div>
              )}

              <div className="order-summary-checkout-page">
                <span className="order-summary-title">
                  {t("checkout-mobile.order-summary")}
                </span>
                <div className="os-upper">
                  <div className="os-first-row">
                    <span className="subtotal order-summary-label">
                      {t("checkout-mobile.subtotal")}
                    </span>
                    <span className="subtotal-ammount order-summary-label">
                      {props.country.toLowerCase() === "saudi arabia"
                        ? "SAR"
                        : props.country.toLowerCase() ===
                          "united arab emirates"
                          ? "AED"
                          : "EGP"}{" "}
                      {
                        props.order?.createdAddress ?
                          props.order?.createdAddress.sub_total
                          :
                          props.cart_items?.sub_total
                      }
                    </span>
                  </div>
                  {
                    props.order?.createdAddress ?
                      props.order?.createdAddress.discount !== "0.0" && (
                        <div className="mt-1 mb-1 os-first-row">
                          <span className="shipping-fee order-summary-label">
                            {t("checkout-mobile.discount")}
                          </span>
                          <span className="subtotal-ammount order-summary-label">
                            {props.country.toLowerCase() === "saudi arabia"
                              ? "SAR"
                              : props.country.toLowerCase() ===
                                "united arab emirates"
                                ? "AED"
                                : "EGP"}{" "}
                            {
                              props.order?.createdAddress ?
                                props.order?.createdAddress.discount
                                :
                                props.cart_items?.discount
                            }
                          </span>
                        </div>
                      )
                      :
                      <div className="mt-1 mb-1 os-first-row">
                        <span className="shipping-fee order-summary-label">
                          {t("checkout-mobile.discount")}
                        </span>
                        <span className="subtotal-ammount order-summary-label">
                          {props.country.toLowerCase() === "saudi arabia"
                            ? "SAR"
                            : props.country.toLowerCase() ===
                              "united arab emirates"
                              ? "AED"
                              : "EGP"}{" "}
                          {
                            props.order?.createdAddress ?
                              props.order?.createdAddress.discount
                              :
                              props.cart_items?.discount
                          }
                        </span>
                      </div>
                  }
                  {props.cart_items?.coupon_code && (
                    <div className="mt-1 mb-1 os-first-row">
                      <span className="shipping-fee order-summary-label">
                        {t("checkout-mobile.coupon")}
                      </span>
                      <span className="subtotal-ammount order-summary-label">
                        {props.cart_items?.coupon_code}
                      </span>
                    </div>
                  )}
                  {props.cart_items?.cashback_amount && <div className="d-flex justify-content-between">
                    <span className="shipping-fee order-summary-label">
                      {t('refer.referral_cashback')}
                    </span>
                    <span className="subtotal-ammount order-summary-label">
                      {props.country.toLowerCase() === 'saudi arabia'
                        ? 'SAR'
                        : props.country.toLowerCase() === 'united arab emirates'
                        ? 'AED'
                        : 'EGP'}{' '}
                      {props.cart_items?.cashback_amount}
                    </span>
                  </div>}
                  <div className="os-second-row">
                    <span className="shipping-fee order-summary-label">
                      {t("checkout-mobile.shipping-fee")}
                    </span>
                    <span className="subtotal-ammount order-summary-label">
                      {
                        is_delivery ?
                          props.order?.createdAddress ?
                            props.order?.createdAddress?.delivery_fee == 0 ?
                              <span className="free-text">FREE</span>
                              :
                              (props.country.toLowerCase() === "saudi arabia"
                                ? "SAR "
                                : props.country.toLowerCase() ===
                                  "united arab emirates"
                                  ? "AED "
                                  : "EGP ") + (props.order?.createdAddress?.delivery_fee)
                            :
                            <span
                              style={{
                                textDecoration: "underline"
                              }}
                              onClick={
                                props.address.length ?
                                  onChangeAddressesClicked
                                  :
                                  onAddAddressClicked
                              }
                            >
                              {
                                props.address.length ?
                                  props.language == "en" ?
                                    en_translation["checkout"]["select-address-to-calculate-shipping-fee"]
                                    :
                                    ar_translation["checkout"]["select-address-to-calculate-shipping-fee"]
                                  :
                                  props.language == "en" ?
                                    en_translation["checkout"]["add-address-to-calculate-shipping-fee"]
                                    :
                                    ar_translation["checkout"]["add-address-to-calculate-shipping-fee"]
                              }
                            </span>
                          :
                          "AED 0.0"
                      }
                    </span>
                  </div>
                  {/* {

                    props.cart_items?.sub_total < 100 &&
                    <div className="suggestfree">
                      <span className="suggesttext">
                        You're Only AED&nbsp;
                        <span className="suggestvalue">
                          {
                            Math.round((suggestme + Number.EPSILON) * 100) / 100
                          }
                        </span>
                        &nbsp;Away From Free Shipping!
                      </span>
                    </div>
                  } */}



                </div>
                <div className="os-bottom">
                  <div className="os-third-row">
                    <span className="subtotal os-total-label">
                      {t("checkout-mobile.total")}

                      <span className="os-total-vat-label">
                        {t("checkout-mobile.inclusive-of-vat")}{" "}
                      </span>
                    </span>
                    <span
                      className="subtotal-ammount os-total-label"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {props.country.toLowerCase() === "saudi arabia"
                        ? "SAR"
                        : props.country.toLowerCase() ===
                          "united arab emirates"
                          ? "AED"
                          : "EGP"}{" "}
                      {
                        props.order?.createdAddress ?
                          props.order?.createdAddress.grand_total
                          :
                          props.cart_items?.sub_total
                      }
                    </span>
                  </div>
                  <div className="os-fourth-row">
                    <span className="shipping-fee order-summary-label">
                      {t("checkout-mobile.estimated-vat")}
                    </span>
                    <span className="subtotal-ammount order-summary-label">
                      {props.country.toLowerCase() === "saudi arabia"
                        ? "SAR"
                        : props.country.toLowerCase() ===
                          "united arab emirates"
                          ? "AED"
                          : "EGP"}{" "}
                      {
                        props.order?.createdAddress ?
                          props.order?.createdAddress.vat
                          :
                          props.cart_items?.vat
                      }
                    </span>
                  </div>
                </div>
                {wallet.flag && (
                  <>
                    <div className="os-upper">
                      <div className="os-second-row">
                        <span className="shipping-fee order-summary-label">
                          {t("checkout-mobile.wallet")}
                        </span>
                        <span className=" order-summary-label green">
                          {props.country.toLowerCase() === "saudi arabia"
                            ? "-SAR"
                            : props.country.toLowerCase() ===
                              "united arab emirates"
                              ? "-AED"
                              : "-EGP"}{" "}
                          {
                            props.order?.createdAddress ?
                              wallet.cost >=
                                parseFloat(
                                  props.order?.createdAddress.grand_total
                                )
                                ? props.order?.createdAddress.grand_total
                                : wallet.cost
                              :
                              wallet.cost >=
                                parseFloat(
                                  props.cart_items.sub_total
                                )
                                ? props.cart_items.sub_total
                                : wallet.cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="os-bottom">
                      <div className="os-third-row">
                        <span className="subtotal os-total-label">
                          {t("checkout-mobile.balance")}
                        </span>
                        <span className="subtotal-ammount os-total-label">
                          {props.country.toLowerCase() === "saudi arabia"
                            ? "SAR"
                            : props.country.toLowerCase() ===
                              "united arab emirates"
                              ? "AED"
                              : "EGP"}{" "}
                          {
                            props.order?.createdAddress ?
                              parseFloat(
                                props.order?.createdAddress.grand_total
                              ) -
                                (wallet.flag ? parseFloat(wallet.cost) : 0) >
                                0
                                ? (
                                  parseFloat(
                                    props.order?.createdAddress.grand_total
                                  ) -
                                  (wallet.flag
                                    ? parseFloat(wallet.cost)
                                    : 0)
                                ).toFixed(2)
                                : 0
                              :
                              parseFloat(
                                props.cart_items.sub_total
                              ) -
                                (wallet.flag ? parseFloat(wallet.cost) : 0) >
                                0
                                ? (
                                  parseFloat(
                                    props.cart_items.sub_total
                                  ) -
                                  (wallet.flag
                                    ? parseFloat(wallet.cost)
                                    : 0)
                                ).toFixed(2)
                                : 0
                          }
                          { }
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {props.cart_items?.line_items?.length > 0 && (
                <div className="review-your-order">
                  {props.cart_items?.line_items?.map((item) => (
                    <ProductDetailCard
                      key={item.id}
                      leadDays={item.orderable.product_lead_days}
                      name={
                        (item.orderable_type === "Product"
                          ? item.orderable[
                          `product_name_${props.language}`
                          ] + " "
                          : "") + item.orderable[`name_${props.language}`]
                      }
                      brand={
                        item.orderable_type !== "Service"
                          ? item.orderable[`brand_name_${props.language}`]
                          : item.orderable[`store_name_${props.language}`]
                      }
                      price={item.price}
                      assembly={item.assembly}
                      originalPrice={item.price}
                      quantity={
                        item.orderable_type === "Service"
                          ? false
                          : item.quantity
                      }
                      image_path={getMobileImage(
                        item.orderable.featured_image_resized,
                        item.orderable.featured_image
                      )}
                      sku={item.orderable.sku}
                      currency={
                        props.country.toLowerCase() === "saudi arabia"
                          ? "SAR"
                          : props.country.toLowerCase() ===
                            "united arab emirates"
                            ? "AED"
                            : "EGP"
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {data && (
          <div className="higher-container">
            <div className="place-order">
              <div onClick={
                is_delivery ?
                  props.order?.createdAddress ?
                    card ?
                      cardData.id ?
                        handleSubmit
                        :
                        () => { }
                      :
                      cod || wallet.flag ?
                        handleSubmit
                        :
                        () => { }
                    :
                    () => { }
                  :
                  props.order?.createdAddress?.pickup_address ?
                    card ?
                      cardData.id ?
                        handleSubmit
                        :
                        () => { }
                      :
                      cod || wallet.flag ?
                        handleSubmit
                        :
                        () => { }
                    :
                    () => { }
              } className={
                "place-order-button" +
                (
                  is_delivery ?
                    props.order?.createdAddress ?
                      card ?
                        cardData.id ?
                          " active-btn"
                          :
                          " inactive-btn"
                        :
                        cod || wallet.flag ?
                          " active-btn"
                          :
                          " inactive-btn"
                      :
                      " inactive-btn"
                    :
                    props.order?.createdAddress?.pickup_address ?
                      card ?
                        cardData.id ?
                          " active-btn"
                          :
                          " inactive-btn"
                        :
                        cod || wallet.flag ?
                          " active-btn"
                          :
                          " inactive-btn"
                      :
                      " inactive-btn"
                )
              }>
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <ClipLoader color="#fff" loading size={20} />
                  </div>
                ) : (
                  t("checkout-mobile.place-order-btn")
                )}
              </div>
            </div>
          </div>
        )}
      </MobileLayoutRenderer>
      {/* </>
        ))} */}

      {redirect && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/thank-you",
            state: props.order?.id,
          }}
        />
      )}
      {redirectToHome && <Redirect push to={{ pathname: "/" }} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.isSignedIn,
    user: state.auth.user,
    address: state.select.address,
    width: state.layout.screenWidth,
    cart_items: state.cart.cart_items,
    order: state.cart.order,
    country: state.language.country,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    load: state.layout.loading,
  };
};
const mapDispatchToProps = { set_cart_items, set_loading, set_addresses };
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutMob);
