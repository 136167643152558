import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Row } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../../../../assets/styles/pages/newproducthomepage.scss";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { AiFillTwitterCircle } from "react-icons/ai";

import WebsiteHeader from "../../../header/2025/WebsiteHeader";
import WebsiteFooter from "../../../Footer/2025/WebsiteFooter";
import InputHints from "react-input-hints";
import { set_home_categories } from "../../../../redux/actions/HomeAction";
import { apiJson } from "../../../../Api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  IoSearchOutline
} from "react-icons/io5";
import EngineerIcon from "../../../../assets/images/homepage/engineer.png";
import CatalogIcon from "../../../../assets/images/homepage/catalog-icon.png";
import b2bIcon from "../../../../assets/images/homepage/b2b.png";
import learnabout from "../../../../assets/images/homepage/learnabout.png";
import catbot1 from "../../../../assets/images/bottomicons/Tools&Supplies.png";

import catbot2 from "../../../../assets/images/bottomicons/Crafts.png";
import catbot3 from "../../../../assets/images/bottomicons/Epoxy.png";
import catbot4 from "../../../../assets/images/bottomicons/Paints&painters.png";
import catbot5 from "../../../../assets/images/bottomicons/SprayPaints.png";
import catbot6 from "../../../../assets/images/bottomicons/WoodFinishes.png";
import catbot7 from "../../../../assets/images/bottomicons/Caulk&Sealant.png";


import offerpic2 from "../../../../assets/images/ouroffers2.png";

import "react-toastify/dist/ReactToastify.css";
import { set_country, set_loading } from "../../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../../redux/actions/NotFoundAction";
import { set_user } from "../../../../redux/actions/AuthAction";
import OffersBanner from "../../../../assets/images/offers-banner.png";
import GetAppImg from "../../../../assets/images/homepage/getapp.png";
import GooglePlayImg from "../../../../assets/images/homepage/googleplay.png";
import AppStoreImg from "../../../../assets/images/homepage/appstore.png";
import Banner1 from "../../../../assets/images/homepage/Banner1.png";
import Banner2 from "../../../../assets/images/homepage/Banner2.png";
import Banner3 from "../../../../assets/images/homepage/Banner3.png";

import VariantsModal from "../../../../components/customer/VariantsModal";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { FaMicrophone } from "react-icons/fa";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const en_translation = require("../../../../assets/locales/en/translation.json");
const ar_translation = require("../../../../assets/locales/ar/translation.json");

const HomePage = (props) => {
  const [offersModalShow, setOffersModalShow] = React.useState(false);
  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);
  const [recentvalue, setrecentvalue] = useState("");
  const [videoModalShow, setVideoModalShow] = useState(false);
  const extraContent = <div>

    <h3>
      Shop From Best Of Alisuq Products & Categories
    </h3>
    <p>
      Save yourself from the constant trips to the superstore. Instead, opt for online shopping with Alisuq UAE store. Pick from a range of products and categories, exclusively available at the biggest online retail store in Dubai.
      <br></br><br></br>
      We are a customer-focused platform with a vision to make online shopping quick and hassle-free. From bestsellers in home appliances, to the finest brands in <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=187&filter_by_category%5B%5D=231&filter_by_category%5B%5D=233&filter_by_category%5B%5D=232"
      }} > flooring material </Link>
      , <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }} > cleaners </Link>, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
      }} > paint products </Link>, we have all the right commodities.
      <br></br><br></br>
      With Alisuq’s range of quality-centric, innovative products, the best merchandise is just a few clicks away. Browse through <Link to={
        "/" + props.countryUrl + "-" + props.language + "/items/products"
      } > our categories </Link> to purchase quality paints, cleaning solutions, plumbing accessories, hand tools, and more from top-notch brands. Our swift payment process, and friendly customer service are what customers like most about us.
    </p>
    <h3>
      Explore Alisuq Categories
    </h3>
    <p>
      Unlock amazing offers on Alisuq favorites! We have curated a range of categories to help you in various fields of work. We directly obtain products from top-notch brands, so you do not have to worry about authenticity and originality.
      <br></br><br></br>
      Now you can make the most out of your orders with Alisuq! Browse these categories to extract maximum functionality and reliable utility from your purchases.
      <br></br><br></br>

      <strong>Building and Construction Materials</strong> – Buy top-quality <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=201&filter_by_category%5B%5D=288&filter_by_category%5B%5D=282&filter_by_category%5B%5D=283&filter_by_category%5B%5D=284&filter_by_category%5B%5D=285&filter_by_category%5B%5D=286&filter_by_category%5B%5D=287"
      }} > adhesives </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=280"
      }} > ceiling tiles </Link>, ladders, and much more for various construction and repair projects.
      <br></br><br></br>
      <strong>Appliances</strong>– Find practical electronics like vacuums, refrigerators, dishwashers, and small <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305"
      }} > kitchen appliances </Link>, to perform your daily household chores effortlessly.
      <br></br><br></br>
      <strong>Cleaners</strong> – Pick from high-efficiency glass cleaners, floor cleaners, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=323"
      }} > stain removers </Link>. You can also find practical <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }} > cleaning equipment </Link> and accessories at Alisuq.
      <br></br><br></br>
      <strong>Bath & Faucets</strong> – Sophisticated and easy to use bathroom and kitchen installments, including faucets, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=219"
      }} > showers </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=222"
      }} > sinks </Link>, and other accessories.
      <br></br><br></br>
      <strong>Electrical Accessories</strong> – Find everything an electrician may possibly need, including cable wires, lighting, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=226"
      }} > circuit breakers </Link> and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=230&filter_by_category%5B%5D=339&filter_by_category%5B%5D=340&filter_by_category%5B%5D=341&filter_by_category%5B%5D=342"
      }}> batteries </Link>.
      <br></br><br></br>
      <strong>Flooring Products</strong> – Redefine your interiors with contemporary designs in <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=231"
      }} > flooring tiles </Link> and paints.
      <br></br><br></br>
      <strong>Decor & Furniture products</strong> – Transforming your interiors into a luxury space is convenient with premium-quality furniture, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=238&filter_by_category%5B%5D=343&filter_by_category%5B%5D=344&filter_by_category%5B%5D=345"
      }}> wall decor </Link>, and lighting.
      <br></br><br></br>
      <strong>Lawn & Garden Maintenance</strong> – Keep your outdoors clean and green with the most in-demand <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=244&filter_by_category%5B%5D=359&filter_by_category%5B%5D=360"
      }} > plants </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=251&filter_by_category%5B%5D=365&filter_by_category%5B%5D=366&filter_by_category%5B%5D=367&filter_by_category%5B%5D=368&filter_by_category%5B%5D=369&filter_by_category%5B%5D=370&filter_by_category%5B%5D=371&filter_by_category%5B%5D=372&filter_by_category%5B%5D=373"
      }} > gardening tools </Link>, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=363"
      }} > pesticides </Link>.
      <br></br><br></br>
      <strong>Kitchenware</strong> – Build a kitchen from scratch or revamp an old kitchen with durable <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=252"
      }} > kitchen cabinets </Link>, faucets, and glassware.
      <br></br><br></br>
      <strong>Hardware & Tools</strong> – Choose from a range of hand and power tools to perform quick repair and installation tasks.
      <br></br><br></br>
      <strong>Outdoor Recreation</strong> – Spend quality time outdoors with the best camping equipment, barbecue grills, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=192&filter_by_category%5B%5D=263&filter_by_category%5B%5D=264&filter_by_category%5B%5D=265"
      }} > travel accessories </Link> from Alisuq.
      <br></br><br></br>
      <strong>Automotive Gear</strong> – We offer the best <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=266"
      }} > automotive oils </Link>, accessories, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=419"
      }} > anti-rust cleaners </Link>. These products can help extend the life and performance of your vehicles. Automotive Gear is the place to go for all your automotive needs.
      <br></br><br></br>
      <strong>Paint Products</strong> - Give your home a novel feel with high quality <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445"
      }} > spray paints </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=274"
      }}> wood finishes </Link>, primers, and <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=271"
      }}> epoxies </Link>.

    </p>
    <h3>
      Adopt A Higher Standard Of Living With Alisuq Top Brands
    </h3>
    <p>
      Alisuq store features bestselling appliances, cleaners, and hardware from industry-leading brands. We adopt a zero-compromise policy on authenticity to maintain the quality of our products. With a performance-centric and tech-efficient stock, shopping with us is reliable and secure.
      <br></br><br></br>
      As a direct contractor of leading brands, we offer a wide array of options. Each of the Alisuq product is hand-picked to deliver long-term utility in construction, renovation, and cleaning tasks.
      <br></br><br></br>
      Shop top-quality home appliances in UAE - integrating cutting-edge technology – from <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=44"
      }}> Karcher </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63"
      }} > Makita </Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=125"
      }}>Bissel</Link>, Phillips, and others.
      <br></br><br></br>
      Our hardware & Tools category is filled with best quality power drills, fasteners, wrenches, pliers, impact drivers, saws, and more. Purchase from Stabila, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=151"
      }}>YATO</Link>,
      <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=73"
      }}>Bosch</Link>, VTOOLs, and others.
      <br></br><br></br>
      Explore our cleaners range to find tough cleaning solutions, sprays, stain removers, in addition to brooms, mops, and all-purpose cleaners. Shop from big brands like Krud Kutter, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=247"
      }}>Astonish</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=248"
      }}>Dr.Beckmann</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_brand%5B%5D=63&filter_by_brand%5B%5D=246"
      }}>Eco Care</Link>.
      <br></br><br></br>
      Purchase from Alisuq top brands to extract maximum practicality from your appliances, kitchenware, and automotive gear. Combining all global and local brands under one roof, we bring consumers a one-stop solution to all their shopping needs.
    </p>
    <h3>
      Largest Platform For Online Home Shopping In UAE & Dubai
    </h3>
    <p>
      Shopping with Alisuq keeps you protected from scams and counterfeits. Our pride lies in the delivery of first-rate, quality-assured products. This way, we make sure that your online home shopping in UAE is free from all risks. Shop from our featured brands that keep luxury and functionality at the top of their priority list.
      <br></br><br></br>
      We are a dedicated team of professionals, with the sole purpose of supplying high-utility home improvement goods throughout the UAE. Browse our range to enjoy the fastest and most convenient shopping, right from the comfort of your home!

    </p>
    <h3>
      Stress-Free Purchasing With The Leading UAE Store

    </h3>
    <p>
      Quit dawdling and start building with the widest array of choices – featuring millions of products in our consumer-trusted retail store.
      <br></br><br></br>
      <li>Alisuq makes online shopping safe and easy with quality assurance and an easy return policy.
      </li>
      <li>Shop to your heart’s content with the best deals and online offers, all available under one roof!
      </li>
      <li>Avail amazing discounts and additional reductions with our occasional seasonal sales.
      </li>
      <li>Save time and money shopping by Alisuq brands that are commended both internationally and locally.
      </li>
      <br></br>
      Our swift payment process lets you pay via cash on delivery or use your credit/debit cards. Enjoy free shipping on big orders that exceed a certain amount.
      <br></br><br></br>
      Your queries and concerns are always tended to as soon as possible with the most devoted customer service of Alisuq.
      <br></br><br></br>
      Explore different categories. Browse through a variety of products. Find the perfect&nbsp;
      <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
      }}>appliances</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=329&filter_by_category%5B%5D=330&filter_by_category%5B%5D=328&filter_by_category%5B%5D=331&filter_by_category%5B%5D=332&filter_by_category%5B%5D=215&filter_by_category%5B%5D=319&filter_by_category%5B%5D=321&filter_by_category%5B%5D=322&filter_by_category%5B%5D=318&filter_by_category%5B%5D=320&filter_by_category%5B%5D=323&filter_by_category%5B%5D=216&filter_by_category%5B%5D=324&filter_by_category%5B%5D=325&filter_by_category%5B%5D=326&filter_by_category%5B%5D=327"
      }}>cleaners</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
      }}>hardware</Link>, <Link to={{
        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
        search: "filter_by_category%5B%5D=240&filter_by_category%5B%5D=346&filter_by_category%5B%5D=347&filter_by_category%5B%5D=348"
      }}>furniture</Link>, and decor for your home today. Shop now for best deals!

    </p>

  </div>

  const [readMore, setReadMore] = useState(false);

  const linkName = readMore ? 'Read Less ▴ ' : 'Read More ▾ '

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { t } = useTranslation();
  let sections = [];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const setSection = (section, position, allProducts) => {
    switch (position) {
      case 2:
        props.set_home_categories({
          positionTwo:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 3:
        props.set_home_categories({
          positionThree:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 4:
        props.set_home_categories({
          positionFour:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 5:
        props.set_home_categories({
          positionFive:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 6:
        props.set_home_categories({
          positionSix:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 7:
        props.set_home_categories({
          positionSeven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 8:
        props.set_home_categories({
          positionEight:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 9:
        props.set_home_categories({
          positionNine:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 10:
        props.set_home_categories({
          positionTen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 11:
        props.set_home_categories({
          positionEleven:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 12:
        props.set_home_categories({
          positionTwelve:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      case 13:
        props.set_home_categories({
          positionThirteen:
            section?.data?.length < 1
              ? { ...section, data: allProducts }
              : { ...section, data: section.data },
        });
        break;
      default:
        sections.push(section);
    }
  };

  useEffect(() => {
    document.title = "Alisuq.com - Buy All Your Home Needs Online, UAE";
  }, []);

  useEffect(() => {
    props.redirect && props?.set_redirect_home(false);
    window.scroll(0, 0);
    props.set_loading(true);
    (localStorage.getItem("access_token") ||
      localStorage.getItem("user_key")) &&
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

    apiJson.homepage.getBanners(props.countryId).then((response) => {
      if (response.success) {
        let primaryBanners = [];
        let secondaryBanners = [];

        response.data.map((banner) =>
          banner.banner_type === "platform_primary"
            ? primaryBanners.push(banner)
            : secondaryBanners.push(banner)
        );
        props.set_home_categories({
          primaryBanners: primaryBanners,
          secondaryBanners: secondaryBanners,
        });
        props.set_loading(false);
      } else if (!response.status) {
        props.set_loading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        props.set_loading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    let allProducts = [];

    apiJson.homepage.getFirstSection(props.countryId).then((response) => {
      if (response.success) {
        const temp = {
          title_en: response.data.section.title_en,
          title_ar: response.data.section.title_ar,
          data: [
            ...response.data.section.products,
            ...response.data.section.services,
          ],
        };
        props.set_home_categories({
          positionOne: {
            id: response.data.section.id,
            title_en: response.data.section.title_en,
            title_ar: response.data.section.title_ar,
            data: [
              ...response.data.section.products,
              ...response.data.section.services,
            ],
          },
        });

        let i = 0;
        response.data.section_ids?.map((id) =>
          apiJson.homepage.getSections(id, props.countryId).then((section) => {
            ++i;
            if (i === 1) {
              temp?.data?.length < 1 &&
                props.set_home_categories({
                  positionOne: { ...temp, data: allProducts },
                });
            }
            if (section.success) {
              section.data.title_en !== "Weekly Sale 1" &&
                section.data.title_en !== "Weekly Sale 2" &&
                section.data.title_en !== "Combo Sale 1" &&
                section.data.title_en !== "Combo Sale 2" &&
                section.data.title_en !== "Brand Favourites 1" &&
                section.data.title_en !== "Brand Favourites 2" &&
                section.data.title_en !== "Brand Favourites 3" &&
                section.data.title_en !== "Offers" &&
                setSection(
                  {
                    id: section.data.id,
                    title_en: section.data.title_en,
                    title_ar: section.data.title_ar,
                    data: [...section.data.products, ...section.data.services],
                  },

                  section.data.position ? section.data.position : null,
                  shuffle(allProducts)
                );
              if (i === response.data.section_ids.length) {
                props.set_home_categories({
                  sections: sections,
                });
              }
            }
          })
        );
      }
    });

    apiJson.allitems
      .getItems(props.countryId, "products", 1, 10)
      .then((response) => {
        response.success
          ? (allProducts = response.data.products)
          : (allProducts = []);
      });

    apiJson.homepage.getCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_home_categories({ categories: response.data });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });

    apiJson.cart.guestUser(props.countryId).then((response) => {
      if (response.success) {
        props.set_user({
          guestUser: response.data.platform_settings.guest_user_checkout,
          guestSignin: false,
        });
      } else {
        props.set_user({
          guestUser: false,
        });
      }
    });
  }, [props.countryId]);

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, product.product_type ? "products" : "services", props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <Fragment>
      <VariantsModal
        show={variantsModalShow}
        onHide={() => setVariantsModalShow(false)}
        closeModal={() => setVariantsModalShow(false)}
        product={selectedProduct}
        selected_sku={selectedSku}
      />

      {/* Video Modal */}
      <Modal
        show={videoModalShow}
        onHide={() => setVideoModalShow(false)}
        centered
      >
        <Modal.Body style={{ background: "#fff", width: "800px", margin: "auto", padding: "20px", paddingTop: '10px' }}>
          {/* Header Section */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
            <h5 style={{ margin: 0, fontWeight: "bold", fontFamily: 'Futura Heavy', letterSpacing: '0.4px' }}>Learn about Alisuq.com</h5>
            <button
              onClick={() => setVideoModalShow(false)}
              style={{
                fontSize: "30px",
                border: "none",
                background: "transparent",
                cursor: "pointer",
              }}
            >
              ×
            </button>
          </div>

          {/* Video Section */}
          <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, textAlign: "center" }}>
            <iframe
              src="https://www.youtube.com/embed/aC22okImkd4?autoplay=1&controls=0&modestbranding=1&rel=0"

              title="Alisuq Video"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      <div className="newproducthomepage">
        <div className="product-home-page-desktop mx-auto" style={{ backgroundColor: "#e3e6e6bf" }}>
          <WebsiteHeader />
          <div
            className="home-banner"
          >
            <div className="all-page-container mx-auto banner-content">
              <div className="aboutvidsec" onClick={() => setVideoModalShow(true)} style={{ cursor: "pointer" }}>
                <img className="aboutvidicon" src={learnabout} /><span className="aboutvid"> Learn about Alisuq.com</span>
              </div>

              <h2 className="mainheadi">The Leading Digital Platform in the Building</h2>
              <h2 className="mainheadi">Materials &amp; Construction Industry</h2>

              <form
                // onSubmit={onSearchBtnClicked}
                className="search-form">
                <InputHints
                  type="search"
                  // onChange={(e) => {
                  //   onSeacrhChange(e.target.value)
                  //   !e.target.value && resetTranscript()
                  // }}
                  className="form-control"
                  placeholders={[
                    t("homePage.search-ph1"),
                    t("homePage.search-ph2"),
                    t("homePage.search-ph3"),
                    t("homePage.search-ph4"),
                    t("homePage.search-ph5"),
                  ]}
                  aria-label="Search"
                  aria-describedby="search-addon"
                  value={recentvalue}
                // onClick={handleSearchClcik}
                />
                <div className="search-box">
                  {/* {listening ? (
              <img
                src={listening_icon}
                alt="Listening"
                style={{
                  marginTop: "5px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={SpeechRecognition.stopListening}
              />
            ) : ( */}
                  <FaMicrophone
                    size="20"
                    color="#404553"
                    onClick={SpeechRecognition.startListening}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                  {/* )} */}
                  <span
                    className="search-icon pointer"
                    id="search-addon"
                  >
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <button className={props.lang === "ar" ? "search-btn-ar" : "search-btn"}><IoSearchOutline size="20" />&nbsp;<span>Search</span></button>
                    </div>
                    {/* {searching ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={18} />
                    </div>
                  ) : (
                    <FaSearch />
                  )} */}
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div className="middle-cards-row">
            <div className="all-page-container row mx-auto p-0">
              <div className="col-md-3">
                <div className="custom-card">
                  <div className="icon-div">
                    <img
                      src={EngineerIcon}
                      className="engineer-icon"
                    />
                  </div>
                  <h4 className="title">500+ CONTRACTORS &amp; DEVELOPERS</h4>
                  <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="custom-card">
                  <div className="icon-div">
                    <img
                      src={CatalogIcon}
                      className="engineer-icon"
                    />
                  </div>

                  <h4 className="title">PRODUCT CATALOG WITH 100K+ SKU’S</h4>
                  <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="custom-card">
                  <div className="icon-div">
                    <img
                      src={b2bIcon}
                      className="engineer-icon"
                    />
                  </div>
                  <h4 className="title">SERVING B2B, B2C, B2B2C & B2G</h4>
                  <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="custom-card">
                  <div className="icon-div">
                    <img
                      src={EngineerIcon}
                      className="engineer-icon"
                    />
                  </div>
                  <h4 className="title">CUSTOMIZED MARKETING SOLUTIONS</h4>
                  <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="middle-section">
            <div className="all-page-container row mx-auto p-0">
              <div className="col-md-3" style={{ paddingLeft: "25px" }}>
                <div className="auth">
                  <div className="w-100">
                    <div className="row mx-0" style={{ justifySelf: "center" }}>
                      <div className="col-md-6" style={{ paddingRight: "7.5px" }}>
                        <button className="active">Register</button>
                      </div>
                      <div className="col-md-6" style={{ paddingLeft: "7.5px" }}>
                        <button>Login</button>
                      </div>
                    </div>
                    <p>Or continue with</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <FaFacebook
                        size={29}
                        color="#0866ff"
                      />
                      <AiFillTwitterCircle
                        size={30}
                        className="mx-3"
                        color="#49a0eb"
                      />
                      <FcGoogle
                        size={30}
                      />
                    </div>
                  </div>
                </div>
                <div className="get-app mt-3">
                  <div>
                    <h5>Get the ALISUQ app</h5>
                    <img
                      src={GetAppImg}
                      className="get-app-img my-2"
                    />
                    <a href="#" style={{ display: "block" }}>
                      <img
                        src={GooglePlayImg}
                        className="mt-2 googleplay-img"
                      />
                    </a>
                    <a href="#" style={{ display: "block" }}>
                      <img
                        src={AppStoreImg}
                        className="mt-2 googleplay-img"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="reasons-to-shop-section">
                  <h5 className="mb-3">More reasons to shop</h5>
                  <div className="row">
                    <div className="col-md-6 left-col">
                      <div className="card">

                      </div>
                    </div>
                    <div className="col-md-6 right-col">
                      <div className="card">

                      </div>
                    </div>
                    <div className="col-md-6 left-col" style={{ marginBottom: "20px" }}>
                      <div className="card">

                      </div>
                    </div>
                    <div className="col-md-6 right-col" style={{ marginBottom: "20px" }}>
                      <div className="card">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingRight: "25px" }}>
                <div className="banners-section">
                  <Link
                    to="/">
                    <img
                      src={Banner1}
                    />
                  </Link>
                  <Link
                    to="/">
                    <img
                      src={Banner2}
                    />
                  </Link>
                  <Link
                    to="/">
                    <img
                      src={Banner3}
                      className="last-img"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="divider"></div>
          <div className="discover-building-solutions">
            <div className="all-page-container mx-auto">
              <div className="row mx-0">
                <div className="col-md-8">
                  <h2>Discover Building Solutions for Every</h2>
                  <h2>Construction Stage</h2>
                </div>
                <div className="col-md-4">
                  <div className="row mx-0">
                    <div className="col-md-6 count-div">
                      <h3 className="m-0 count">100K+</h3>
                      <p className="m-0">products</p>
                    </div>
                    <div className="col-md-6 count-div">
                      <h3 className="m-0 count">500K+</h3>
                      <p className="m-0">products</p>
                    </div>
                    <div className="col-md-6 count-div mt-5">
                      <h3 className="m-0 count">200K+</h3>
                      <p className="m-0">products</p>
                    </div>
                    <div className="col-md-6 count-div mt-5">
                      <h3 className="m-0 count">400K+</h3>
                      <p className="m-0">products</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="categories-section">
            <div className="all-page-container mx-auto">
              <Swiper className="swipebul"
                slidesPerView={10} // or 'auto'
                slidesPerColumn={2}
                slidesPerColumnFill="row"
                spaceBetween={20}
                pagination={false}
                navigation
              >
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide
                  onClick={(e) => e.preventDefault()}
                  key="0"
                >
                  <Link
                    key="0"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        "/" +
                        props.countryUrl +
                        "-" +
                        props.language +
                        "/offers"
                    }}
                  >
                    <div className="img-cont">
                      <div className="img-category">

                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="divider"></div>
          <div className="onboarding">
            <div className="all-page-container mx-auto">
              <div className="row mx-0" style={{ justifyContent: "center" }}>
                <h2 className="title" style={{ zIndex: "999" }}>Effortless Onboarding for Vendors</h2>
              </div>
              <div className="row mx-0">
                <div className="col-md-5">
                  <h3>What You Get</h3>

                  <ul className="mt-5">
                    <li>No forms to fill</li>
                    <li>No hastle</li>
                    <li>Just show up for a meeting</li>
                  </ul>

                  <ul>
                    <li>No need to write descriptions.</li>
                    <li>No image uploads or edits.</li>
                    <li>We create everything for you.</li>
                  </ul>

                  <ul>
                    <li>No setup required.</li>
                    <li>No maintenance work.</li>
                    <li>Just monitor your store's success</li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="pt-5">
                      <div className="step">
                        <p>1</p>
                      </div>
                      <p>Meet, Register &amp; Sign!</p>
                      <div className="lines">
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                      </div>
                      <div className="step">
                        <p>2</p>
                      </div>
                      <p>Content Magic</p>
                      <div className="lines">
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                        <p style={{ writingMode: "vertical-rl", paddingBottom: "4px" }}>-</p>
                      </div>
                      <div className="step">
                        <p>3</p>
                      </div>
                      <p>Live &amp; Selling</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="data">
                    <h3>What We Do</h3>

                    <ul className="mt-5">
                      <li>Meet to understand your business.</li>
                      <li>Handle registration &amp; digital signing.</li>
                      <li>Handle store creation.</li>
                    </ul>

                    <ul>
                      <li>Prepare your product catalog.</li>
                      <li>Create high-quality product images.</li>
                      <li>Write compelling product descriptions.</li>
                      <li>Ensure accurate product listings.</li>
                    </ul>

                    <ul>
                      <li>Publish your products online.</li>
                      <li>Run marketing compaigns.</li>
                      <li>Ensure dynamic AI pricing.</li>
                      <li>Ensure smooth sales operations.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>

          <div className="our-warehouse">
            <div className="all-page-container mx-auto">
              <h2 className="warehousetitle">Direct Supply from Our Warehouse</h2>
              <div className="row">


                <div className="col-md-4">
                  <div className="custom-card-wholesale">
                    <h4 className="title">Take Alisuq Warehouses Live tours</h4>

                  </div>

                </div>

                <div className="col-md-4">
                  <div className="custom-card-wholesale2">
                    <h4 className="title">500+ CONTRACTORS &amp; DEVELOPERS</h4>


                  </div>

                </div>

                <div className="col-md-4">
                  <div className="custom-card-wholesale3">
                    <h4 className="title">500+ CONTRACTORS &amp; DEVELOPERS</h4>

                  </div>

                </div>








              </div>
            </div>
          </div>
          <div className="emptyspacer"></div>
          <div className="emptyspacer"></div>

          <div className="emptyspacer"></div>
          <div className="divider"></div>
          <div className="onboarding trade">
            <div className="all-page-container mx-auto">
              <div className="row mx-0" style={{ justifyContent: "left" }}>
                <h2 className="tradetitle" style={{ zIndex: "999" }}>Trade with confidence from<br></br>production quality to purchase<br></br>protection</h2>
              </div>
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="custom-card-trade">
                    <h4 className="title">Ensure production quality with</h4>

                    <img className="tradeicon" src="https://s.alicdn.com/@img/imgextra/i3/O1CN01cnsiSd1sFb5vxUBwd_!!6000000005737-2-tps-1200-210.png_q60.jpg" />
                    <span className="tradedesc">Connect with a variety of suppliers with third-party-verified credentials and capabilities. Look for the "Verified" logo to begin sourcing with experienced suppliers your business could rely on.</span>
                    <button className="tradevidbtn"><img className="aboutvidicon" src={learnabout} />Watch video</button>

                  </div>


                </div>

                <div className="col-md-6">
                  <div className="custom-card-trade">
                    <h4 className="title">Protect your purchase with</h4>

                    <img className="tradeicon" src="https://s.alicdn.com/@img/imgextra/i1/O1CN01I0ebSF1UCntpAivUU_!!6000000002482-2-tps-1200-210.png_q60.jpg" />
                    <span className="tradedesc">Source confidently with access to secure payment options, protection against product or shipping issues, and mediation support for any purchase-related concerns when you order and pay on Alibaba.com.</span>
                    <button className="tradevidbtn"><img className="aboutvidicon" src={learnabout} />Watch video</button>

                  </div>


                </div>
              </div>
            </div>

          </div>
          <div className="divider"></div>
          <div className="personalized-recommendations">
            <div className="all-page-container mx-auto">
              <h3>See personalized recommendations</h3>
              <Link
                to="/signup"
                className="register-btn"
              >Register</Link>
              <p>New customer? <Link to="/signup">Start here</Link></p>
            </div>
          </div>

          <div className="whitecard hidde">
                <div className="sectiontitle-container-left">
                  <h2 className="section-title lft catsec">
                    {
                      props.language == "en" ?
                        en_translation["homePage"]["paints-heading"]
                        :
                        ar_translation["homePage"]["paints-heading"]
                    }
                    {/* {t("homePage.paints-heading")} */}
                    <div className="ViewAllbtn catsecbtn">
                      <Link
                        to={{
                          pathname: "/" + props.countryUrl +
                            "-" + props.language +
                            "/items/products",
                          search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"
                        }}
                      >
                        {
                          props.language == "en" ?
                            en_translation["all-categories"]["view-all"]
                            :
                            ar_translation["all-categories"]["view-all"]
                        }
                        {/* {t("all-categories.view-all")} */}
                      </Link>
                    </div>
                  </h2>
                </div>
                <div class="emptyspacer-big"></div>
                <div class="emptyspacer"></div>
                <div class="emptyspacer"></div>
                <div className="one1">
                  <div className="two2"></div>
                  <div className="three3">

                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=269&filter_by_category%5B%5D=423&filter_by_category%5B%5D=425&filter_by_category%5B%5D=426&filter_by_category%5B%5D=427&filter_by_category%5B%5D=428&filter_by_category%5B%5D=430&filter_by_category%5B%5D=424&filter_by_category%5B%5D=429&filter_by_category%5B%5D=431"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot1}>

                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=270&filter_by_category%5B%5D=432&filter_by_category%5B%5D=436&filter_by_category%5B%5D=437&filter_by_category%5B%5D=438"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot2}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=271"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot3}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=272&filter_by_category%5B%5D=439&filter_by_category%5B%5D=441&filter_by_category%5B%5D=442&filter_by_category%5B%5D=440&filter_by_category%5B%5D=443"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot4}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=273&filter_by_category%5B%5D=444&filter_by_category%5B%5D=445"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot5}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=274"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot6}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="four4">
                      <div>
                        <Link
                          to={{
                            pathname: "/" + props.countryUrl +
                              "-" + props.language +
                              "/items/products",
                            search: "?filter_by_category%5B%5D=275&filter_by_category%5B%5D=446&filter_by_category%5B%5D=447"
                          }}
                        >
                          <div className="six6 catsecpic">
                            <img src={catbot7}>
                            </img>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="emptyspacer-big"></div>
                <div class="emptyspacer-big"></div>
              </div>




          <div className="category-content hidde">
                <h1>
                  Alisuq - Your One-Stop Shop For All Online Shopping In UAE
                </h1>
                <p>
                  Following the legacy of authenticity, durability, and practicality, Alisuq brings you an exclusive platform for online shopping in UAE. We are dedicated to incorporating convenience into your daily projects. From repair, maintenance, cooking, cleaning, and general home-care tasks, we bring assistance in every task.
                </p>

                <p>
                  Alisuq categories are created with the utmost consideration and care. Whether you’re looking for
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=190&filter_by_category%5B%5D=255&filter_by_category%5B%5D=256&filter_by_category%5B%5D=252&filter_by_category%5B%5D=253&filter_by_category%5B%5D=254"
                  }} > Kitchenware </Link>,
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=262&filter_by_category%5B%5D=257&filter_by_category%5B%5D=260&filter_by_category%5B%5D=261&filter_by_category%5B%5D=258"
                  }}> hardware & tools </Link>,
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=182&filter_by_category%5B%5D=195&filter_by_category%5B%5D=196&filter_by_category%5B%5D=197&filter_by_category%5B%5D=198&filter_by_category%5B%5D=203&filter_by_category%5B%5D=199&filter_by_category%5B%5D=200&filter_by_category%5B%5D=201&filter_by_category%5B%5D=202&filter_by_category%5B%5D=204"
                  }}> building & construction materials </Link> , or
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289&filter_by_category%5B%5D=206&filter_by_category%5B%5D=291&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=294&filter_by_category%5B%5D=295&filter_by_category%5B%5D=296&filter_by_category%5B%5D=297&filter_by_category%5B%5D=298&filter_by_category%5B%5D=299&filter_by_category%5B%5D=300&filter_by_category%5B%5D=301&filter_by_category%5B%5D=302&filter_by_category%5B%5D=303&filter_by_category%5B%5D=304&filter_by_category%5B%5D=305&filter_by_category%5B%5D=211&filter_by_category%5B%5D=306&filter_by_category%5B%5D=307&filter_by_category%5B%5D=308&filter_by_category%5B%5D=309&filter_by_category%5B%5D=212&filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450&filter_by_category%5B%5D=311&filter_by_category%5B%5D=452&filter_by_category%5B%5D=453&filter_by_category%5B%5D=451&filter_by_category%5B%5D=312&filter_by_category%5B%5D=313&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=213&filter_by_category%5B%5D=314&filter_by_category%5B%5D=315&filter_by_category%5B%5D=316&filter_by_category%5B%5D=317"
                  }}> home appliances </Link>,
                  we have a large array of options in our UAE store. Revamp your home with the best products from popular UAE brands. As a platform that gives prime importance to customer expectations, we offer the finest products at the most market-competitive rates. Save big on your major and minor purchases with Alisuq. Choose from a wide selection of homecare, indoor designing, and
                  <Link to={{
                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=192&filter_by_category%5B%5D=263&filter_by_category%5B%5D=264&filter_by_category%5B%5D=265"
                  }} > outdoor recreation </Link>
                  products to upgrade your lifestyle.
                  <br></br><br></br>
                  Alisuq team works day and night to bring customers the best shopping experience. We aspire to provide a swift purchasing and shipping process, so all your orders are delivered on time. Our team offers quick and reliable services to make online shopping hassle-free. With quality products that outperform in challenging fields, you can rely on us to deliver performance.
                  <br></br><br></br>
                  Practice secure online home shopping in UAE right from your couch or living room. Avail amazing offers and discounts on leading brands in the construction, tools, gardening, crafts, and electronics industry. Get your orders delivered across Dubai, Abu Dhabi, and other cities of the UAE in no time.
                </p>

                {readMore && extraContent}

                <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}><span>{linkName}</span></a>



              </div>
          {!props.loading && (
            <>
              <div className="content-wrapper">

              </div>


              <div>
                <OffersModal
                  show={offersModalShow}
                  onHide={() => setOffersModalShow(false)}
                />
              </div>

              <WebsiteFooter />
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

function OffersModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="offers-modal"
    >
      <Modal.Body>
        <Link to={props.countryUrl + "-" + props.language + "/uae-en/electrical-accessories-sale"}>
          <div className="text-center">
            <img src={OffersBanner} className="img img-fluid" style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }} />
          </div>
        </Link>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.home.secondaryBanners,
    countryId: state.language.countryId,
    country: state.language.country,
    recommendedForYou: state.home.recommendedForYou,
    diyProducts: state.home.diyProducts,
    paintersTouch: state.home.paintersTouch,
    krudKutter: state.home.krudKutter,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    positionSix: state.home.positionSix,
    positionSeven: state.home.positionSeven,
    positionEight: state.home.positionEight,
    positionNine: state.home.positionNine,
    positionTen: state.home.positionTen,
    positionEleven: state.home.positionEleven,
    positionTwelve: state.home.positionTwelve,
    positionThirteen: state.home.positionThirteen,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    categories: state.header.allCategories,
    redirect: state.notFound.redirect,
    loading: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
