import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import CopyButton from "./CopyButton";
import { getCurrencyOfCountry } from "../../../utils/utils";

function ReferLinkModel({
  isOpen,
  isSmall,
  amount,
  language,
  country,
  setIsOpen,
  referralLink,
}) {
  const { t } = useTranslation();

  const fbSendUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    referralLink
  )}`;

  const whatsappMessage = t(`refer.referral_message`, {
    amount: getCurrencyOfCountry(country) + " " + parseFloat(amount),
    referralLink: referralLink,
  });

  return (
    <Modal
      centered
      size={isSmall ? "sm" : "lg"}
      show={isOpen}
      onHide={() => setIsOpen(false)}
      closeModal={() => setIsOpen(false)}
      className={isSmall ? "service-modal-mobile" : "service-modal"}
    >
      <Modal.Header
        style={{
          justifyContent: "center",
          paddingBottom: "0px",
        }}
      >
        <Modal.Title>
          <h5
            style={{
              fontSize: isSmall ? "20px" : "24px",
              padding: "0 1rem 1rem",
              margin: "0",
              fontWeight: 700,
            }}
            className="text"
          >
            {t("refer.title")}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "24px",
            gap: "20px",
          }}
        >
          <div
            style={{ fontSize: isSmall ? "12px" : "16px", textAlign: "center" }}
          >
            {referralLink}
          </div>

          {amount && (
            <div
              style={{
                textAlign: "initial",
                textAlign: "center",
                margin: 0,
                fontSize: isSmall ? "12px" : "16px",
              }}
              className="gray below-title"
            >
              {t("refer.referral", {
                amount:
                  getCurrencyOfCountry(country) + " " + parseFloat(amount),
              })}
            </div>
          )}

          <div
            className="d-flex justify-content-center"
            style={{ gap: "10px" }}
          >
            <a
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                whatsappMessage
              )}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FaWhatsapp
                size={30}
                color="#4dc247"
                style={{
                  ...(language === "en"
                    ? { marginRight: "5px" }
                    : { marginLeft: "5px" }),
                }}
              />
            </a>

            <a href={fbSendUrl} target="_blank">
              <FaFacebook size={30} />
            </a>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <CopyButton referralLink={referralLink} />
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.language,
  country: state.language.country,
});

export default connect(mapStateToProps, null)(ReferLinkModel);
